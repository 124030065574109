import React, { useState, useEffect } from "react";
import { useRouteLoaderData, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useApi, useCommunityList, useTemplate, usePic } from "../../services/servicesContext";
import { PopoverDetails } from "../popoverDetails/popoverDetails";
import Select from "react-select";
import { PhSankeyComponent } from "../sankey/sankeyComponent";
import "./modalGeneralFlow.scss";

export function GeneralFlowModal(props) {

    const {show, onClose, api} = props

    const [providerName, setProviderName] = useState(null);
    const [relations, setRelations] = useState(null);
    const [options, setOptions] = useState(null);
    const [homeUrl, setHomeUrl] = useState(null);

    const template = useTemplate();
    const pic = usePic();

    const graphOptions = {
        node: {
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else { // Community AKA 'Others'
                    return d.name; 
                }
            }
        }
    };

    async function getRelations() {

        try {
            if (props?.provider?.npi) {
                let data = await api.Relations(props.provider.npi, 'outbound', { classification: 'General Acute Care Hospital' });

                if (data.length === 0) {
                    console.log('No Provider General Flow data found');
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Provider General Flow data found.',
                        delay: 30000
                    });
                }

                data = data.map(relation => {
                    relation.target = {
                        side: 'right',
                        __proto__: relation.target
                    }
                    return relation;
                });

                let topN = data.sort(function (a, b) {
                    if (graphOptions?.link?.value) {
                        return graphOptions.link.value(b) - graphOptions.link.value(a);
                    } else {
                        return b.values.shared - a.values.shared;
                    }
                }).splice(0, 19);

                if (data.length > 0) {
                    let others = data.reduce(function (rel, item) {
                        rel.values.shared += item.values.shared;
                        rel.values.sameday += item.values.sameday;
                        rel.values.unique += item.values.unique;
                        rel.target.members.push(item.target);

                        return rel;

                    }, new api.Relation({
                        source: data[0].source,
                        target: new api.Community({ name: 'others', members: [] }),
                        values: {
                            shared: 0,
                            sameday: 0,
                            unique: 0
                        },
                    }));

                    topN.push(others);
                }

                setOptions(graphOptions);
                setRelations(topN);

            }
        } catch (err) {
            setLoading(false);
            console.error('error requesting relations', err);
        }
    }

    useEffect(() => {

        if (show) {
            setProviderName(props.provider.parent.name);
            setHomeUrl("/physician" + props.provider.url);
            

            getRelations();
        }

    }, [show]);

    return (
        <div className="generalFlow-modal">
         <Modal show={show} onHide={onClose} dialogClassName="modal-lg-generalFlow">
                <Modal.Header closeButton>
                    <h3 className="modal-title" id="modal-title-search">General Flow for {providerName}</h3>
                </Modal.Header>
                <Modal.Body className="sankey-modal-body">
                    <div className="sankey-report-container1">
                        <PhSankeyComponent
                            data={relations}
                            opts={options}
                            pic={pic}
                            template={template}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <Link to={homeUrl} onClick={onClose} className="back-button"><i className="fa fa-arrow-left" aria-hidden="true"></i>Go to Provider Home</Link>
                            <button className="btn btn-warning" type="button" onClick={onClose}>Cancel</button>
                    </div>
                </Modal.Footer>
          </Modal>
        </div>
      )

}