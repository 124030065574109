import React, {useState, useEffect, useRef} from "react";
import { react2angular } from 'react2angular';
import angular from 'angular';

import merge from 'lodash/merge';

import pic from '../../services/pic';
import template from '../../services/template';

import * as d3Base from 'd3';
import { sankeyCircular as d3SankeyCircular } from 'd3-sankey-circular';
import {event as d3SelectionEvent} from 'd3-selection';
import { legendColor } from 'd3-svg-legend';

import * as parseD from 'd-path-parser';

const componentName = 'sankeyGraph';
const moduleName = 'sankeyModule';

const d3 = angular.extend({}, d3Base, { sankey: d3SankeyCircular, legendColor });

// workaround for webpack breaking d3.event
// https://github.com/d3/d3-zoom/issues/32#issuecomment-229889310
d3.getEvent = () => d3SelectionEvent;// call and assign to variable wherever d3.event is needed

function phSankey(init) {
    let sankey;

    let defaults = {
        color: {
            scale: d3.scaleOrdinal(d3.schemeCategory10),
            value: function(d) {
                return this.scale(d);
            }
        },
        node: {
            value: function(d) { return d.valueOf() + ''; },
            colorValue: function(d) { return d.valueOf() + ''; },
            label: function(d) { return d.toString(); },
            width: 25,
            padding: 15,
            opacity: {
                full: 1,
                partial: .2
            }
        },
        link: {
            value: function(d) { return d.valueOf(); },
            opacity: {
                full: 1,
                partial: .5
            }
        },
        controlled: false,
        dimensions: {
            margin: {
                top: 10,
                right: 0,
                bottom: 10,
                left: 0
            },
            container: {},
            sankey: {}
        },
        toolTip: {
            datum: false,
            units: 'Visits',
            value: function(d) {
                return d.valueOf();
            },
            isRelation: function(d) {
                return d instanceof api.Relation;
            },
            cursorOffset: 5,
            calcLeftOffset: function(eventObj) {
                return (eventObj.pageX > 450 ? eventObj.pageX - 220 - this.cursorOffset : eventObj.pageX + 5 + this.cursorOffset);
            },
            calcTopOffset: function(eventObj){
                return eventObj.pageY - 55;
            },
            qtyFormat: function (d) {
                return d3.format(",")(d);
            },
            sourceName: function(d){
                return d.source.toString();
            },
            targetName: function(d){
                return d.target.toString();
            },
            template: ``
        },
        colorLegend: false
    };

    let elem;
    let svg;
    let legend;
    let legendContainer;
    let legendRect;
    let resizeTimer;

    let toolTip;
    let toolTipRelation;
    let toolTipNode;

    let processedRelations;
    let graphRenderData;

    let options = merge({}, defaults);

    if (init.elem) {
        elem = init.elem;
        svg = d3.select(elem);
        updateDimensions();
        buildGraph({
            nodes: [],
            links: []
        });
    }

    if (!toolTip) {
        // create tooltip
        toolTip = d3.select('body')
            .append('div')
            .attr('class', 'sankeyTooltip')
            .style('left', '25px')
            .style('top', '25px')
            .style('display', 'none')
            .style('z-index', 10001);
        
        toolTipRelation = toolTip.append('div')
            .attr('id', 'tooltip-relation');
        
        toolTipNode = toolTip.append('div')
            .attr('id', 'tooltip-node');

    }

    function processRelations(relations) {
        return {
            nodes: relations.reduce(function(nodes, relation) {
                    if (nodes.indexOf(relation.source) == -1) nodes.push(relation.source);
                    if (nodes.indexOf(relation.target) == -1) nodes.push(relation.target);
                    return nodes;
                }, []).map(function(node) {
                    var ret = {
                        id: options.node.value(node)
                    };
                    
                    ret.__proto__ = node;
                    
                    return ret;
                }),
            links: relations.map(function(relation) {
                    var ret = { 
                        source: options.node.value(relation.source),
                        target: options.node.value(relation.target),
                        value: options.link.value(relation)
                    };
                    
                    if (relation.origin) ret.origin = relation.origin;
                    if (relation.destination) ret.destination = relation.destination;
                    
                    ret.__proto__ = relation;
                    
                    return ret;
                })
        };
    }

    function updateDimensions(){
        options.dimensions.container = {
            width: elem.parentNode.clientWidth,
            height: (elem.parentNode.clientHeight - 5)//TODO: handle growing svg element on axis change or filtering without - 5
        };
        
        options.dimensions.sankey = {
            width: options.dimensions.container.width - (options.dimensions.margin.left + options.dimensions.margin.right),    
            height:  options.dimensions.container.height - (options.dimensions.margin.top + options.dimensions.margin.bottom)
        };
    }

    function buildGraph(data) {
                
        svg.selectAll('*').remove();
        
        var innerMarginG = svg.append('g')
            .attr('transform', 'translate('+(options.dimensions.margin.left)+','+(options.dimensions.margin.top)+')');
        
        var links = innerMarginG.append("g")
            .attr('class', 'links')
            .selectAll("g.link-container");

        links = links.data(data.links)
            .enter()
            .append('g')
            .attr('class', function(d) {
                if (typeof options.link.class == 'string') {
                    return 'link-container ' +options.link.class;
                } else if ( typeof options.link.class == 'function') {
                    return 'link-container ' +options.link.class(d);
                } else {
                    return 'link-container';
                }
            })
            .on('click', function(d, i, nodes){
                const d3event = d3.getEvent();
            });
        
        var linkPaths = links.append("path")
            .attr('class', 'link')
            .attr("d", function(d){
                return d.path;
            })
            .attr("stroke-width", function(d) { return d.width; });
            
        if (options.link.arrows) {
            var linkArrowHeads = options.link.arrows.appendArrowHeads(links);
            var linkArrowTails = options.link.arrows.appendArrowTails(links);
        }
        
        if (options.link.animatedDashes) {
            links.append('g')
                .attr('class', 'g-dashes')
                .call(options.link.animatedDashes.appendDashes);
            var animatedDashesPaths = d3.select('g.links').selectAll('g.g-dashes').selectAll('path');
        }
        
        var nodes = innerMarginG.append('g')
            .attr('class', 'nodes')
            .attr('fill', 'none')
            .attr('stroke', '#000')
            .attr("stroke-opacity", 0.2)
            .selectAll('g');
            
        nodes = nodes.data(data.nodes)
            .enter()
                .append("g")
                .attr('class', 'node')
                .on('click', function(d, i, nodes){
                    const d3event = d3.getEvent();
                    let eventdata = Object.getPrototypeOf(d);
                    let entitytype = eventdata.entitytype;
                    if (options?.node?.handleClick) {
                        options.node.handleClick(d3event, d);
                    } else {
                        if (eventdata.entityTypeClassName == 'Community') {
                            window.open((`community/${d.id}/home`), '_blank');
                        }
                        else if(entitytype == 1) {
                            window.open((`physician/${d.id}/home`), '_blank');
                        }
                        else if (entitytype == 2) {
                            window.open((`organization/${d.id}/home`), '_blank');
                        }
                    }
                })
                .on('mousemove', function(d, i, nodes){
                    const d3event = d3.getEvent();
                    
                    toolTip
                        .style('left', `${options.toolTip.calcLeftOffset(d3event)}px`)
                        .style('top', `${options.toolTip.calcTopOffset(d3event)}px`)
                        .style('display', 'block')
                        .html(`
                        <div>
                            <p><strong>${d.toString()}</strong></p>
                            <hr style="margin:8px 0px;"/>
                            ${(d.targetLinks.length && !options.toolTip.calcSharedTotal) ? `
                                <div>
                                    <span>Receives: ${options.toolTip.qtyFormat(d.targetLinks.reduce((acc, val) => ( acc + options.toolTip.value(val) ), 0))} ${options.toolTip.units}</span>
                                </div>`:
                            ''}
                            ${(d.sourceLinks.length && !options.toolTip.calcSharedTotal) ? `
                                <div>
                                    <span>Refers: ${options.toolTip.qtyFormat(d.sourceLinks.reduce((acc, val) => ( acc + options.toolTip.value(val) ), 0))} ${options.toolTip.units}</span>
                                </div>
                            `:
                            ''}
                            ${ options.toolTip.calcSharedTotal
                                ? `<div>
                                    <span>Receives: ${options.toolTip.qtyFormat(options.toolTip.calcSharedTotal(d, 'recieves'))}</span>
                                </div>
                                <div>
                                    <span>Refers: ${options.toolTip.qtyFormat(options.toolTip.calcSharedTotal(d, 'refers'))}</span>
                                </div>`
                                : ''
                            }
                            ${options.pic(d).length > 0 ? `
                            <div>
                                <strong>Communities: </strong><a>${options.pic(d).map(com => com.abbr).join(',')}</a>
                            </div>
                            ` :''}
                        </div>
                        `);;
                })
                .on('mouseleave', function(d, i, nodes){
                    const d3event = d3.getEvent();
                    
                    toolTip
                        .style('display', 'none');
                })
                .call(
                    d3.drag()
                        .subject(function(d){return d})
                        .on('drag', dragmove)
                );
                
        var nodeRects = nodes.append("rect")
            .attr("x", function (d) {
                if (options.link.arrows) {
                    return d.x0 + (options.link.arrows.directedOffset);
                } else {
                    return d.x0;
                }
            })
            .attr("y", function (d) { return d.y0 })
            .attr("data-side", function(d) {
                if (d.side) {
                    return d.side;
                }
            })
            .attr("height", function (d) {
                return d.y1 - d.y0;
            })
            .attr("width", function(d) {
                if (options.link.arrows) {
                    return d.x1 - d.x0 - (options.link.arrows.directedOffset * 2);
                } else {
                    return d.x1 - d.x0;
                }
            })
            .attr("fill", function(d) { 
                return options.color.value(
                    options.node.colorValue(d)
                );
            });
        
        nodes.append("text")
            .attr("x", function(d) {
                return d.x0 < options.dimensions.sankey.width / 2 ? d.x1 + 6 : d.x0 - 6;
            })
            .attr("y", function(d) {
                return (d.y1 + d.y0) / 2;
            })
            .attr("dy", "0.35em")
            .attr("text-anchor", function(d) {
                return d.x0 < options.dimensions.sankey.width / 2 ? "start" : "end";
            })
            .text(options.node.label);
            
        nodes.append('text')
            .attr('x', function(d) {
                if (options.link.arrows) {
                    return d.x0 + 5 + (options.link.arrows.directedOffset);
                } else {
                    return d.x0 + 5;
                }
            })
            .attr('y', function(d){
                return (d.y1 + d.y0) / 2;
            })
            .attr('dy', '.35em')
            .attr('font-family', 'FontAwesome')
            .attr('font-size', '1em')
            .html(function(d){
                if (options.pic(d).length){
                    return '&#xf0c0;';
                } else if (options.currentCommunityPic) {
                    return options.currentCommunityPic(d) ? '&#xf0c0;' : '';
                } else {
                    return '';
                }
            });
            
        nodeRects
            .on('mouseenter', function(datum){
                nodes.classed('no-hover', function(d){
                    if (d.sourceLinks.concat(d.targetLinks).filter(function(relation){
                        if (relation.source == datum || relation.target == datum) {
                            return true;
                        } else {
                            return false;
                        }
                    }).length === 0) {
                        return true;
                    } else {
                        return false;
                    }
                });
                links.classed('no-hover', function(d){
                    if (d) {
                        if (d.source == datum || d.target == datum) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    
                });
            })
            .on('mouseleave', function(datum){
                nodes.classed('no-hover', false);
                links.classed('no-hover', false);
            });
            
        links
            .on('mousemove', function(datum, i, theNodes){

                nodes.classed('no-hover', function(d){
                    if (d.sourceLinks.concat(d.targetLinks).indexOf(datum) == -1) {
                        return true;
                    } else {
                        return false;
                    }
                });
                
                links.classed('no-hover', function(d){
                    
                    if (options.link && options.link.hoverClassCheck) {//community butterfly and specialty flow sankey
                        return options.link.hoverClassCheck(d, datum);
                    } else {// all other sankeys
                        if (d.source == datum.source && d.target == datum.target && d._reversed == datum._reversed) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    
                });
                
                const d3event = d3.getEvent();
                
                toolTip
                    .style('left', `${options.toolTip.calcLeftOffset(d3event)}px`)
                    .style('top', `${options.toolTip.calcTopOffset(d3event)}px`)
                    .style('display', 'block')
                    .html(`
                    <div>
                        <p><strong>${options.toolTip.sourceName(datum)}</strong></p>
                        <p>${options.toolTip.arrow ? options.toolTip.arrow(datum) : '&rarr;'}</p>
                        <p><strong>${options.toolTip.targetName(datum)}</strong></p>
                        <hr style="margin:8px 0px;"/>
                        <p>${options.toolTip.qtyFormat(options.toolTip.value(datum))} ${options.toolTip.units}</p>
                    </div>
                    `);
            })
            .on('mouseleave', function(datum, i, theNodes){

                nodes.classed('no-hover', false);
                links.classed('no-hover', false);
                
                setTimeout(function(datum, i, theNodes, d3Event){
                    
                    toolTip
                        .style('display', 'none');
                }, 50);
            });
            
        if (options.colorLegend === true && data.nodes.length > 0) {
            
            legendContainer = svg
                .append('g')
                .attr('class', 'legend')
                .style('cursor', 'move')
                .attr('transform', 'translate('+(options.dimensions.margin.left)+','+(options.dimensions.margin.top)+')');
            
            legendRect = legendContainer.append('rect');
            
            var activeColors = data.nodes.reduce(function(acc, node){//lookup table for legend colors/labels
                var colorVal = options.node.colorValue(node);
                if(!acc[colorVal]){
                    acc[colorVal] = true;
                    return acc;
                } else {
                    return acc;
                }
            }, {});
        
            legend = d3.legendColor()
                .shape('path', d3.symbol().type(d3.symbolCircle).size(150)())
                .shapePadding(10)
                .scale(options.color.scale)
                .cellFilter(function(d){
                    if (activeColors[d.label]) {
                        return true;
                    } else {
                        return false;
                    }
                });
            
            var legendCellsContainer = legendContainer.append('g')
                .attr('transform', 'translate('+(options.dimensions.margin.left + 10)+','+(options.dimensions.margin.top)+')');
                
            legendCellsContainer.call(legend);
            
            var legendDims = legendContainer.node().getBoundingClientRect();
            
            legendRect
                .attr('width', (legendDims.width + options.dimensions.margin.left + 25))//added 25 width for [+]/[-]
                .attr('height', (legendDims.height  + options.dimensions.margin.top));
        
            var legendButton = legendContainer
                .append('text')
                .attr('text-anchor', 'end')
                .style('cursor', 'pointer')
                .attr('transform', 'translate('+(legendDims.width + options.dimensions.margin.left - 3 + 25)+','+(options.dimensions.margin.top + 3)+')')//added 25 width for [+]/[-]
                .text('[-]')
                .on('click', function(d){
                    var button = d3.select(this);
                    if (button.text() === '[-]') {
                        button.text('[+]');
                        legendCellsContainer.style('display', 'none');
                        legendRect.attr('height', (options.dimensions.margin.top + options.dimensions.margin.bottom));
                    } else {
                        button.text('[-]');
                        legendCellsContainer.style('display', 'inline');
                        legendRect.attr('height', (legendDims.height + options.dimensions.margin.top));
                    }
                });
            
            legendContainer.call(
                d3.drag()
                    .subject(function(d){
                        if (d) {
                            return d;
                        } else {
                            var t = d3.select(this);
                            var tr = getTranslation(t.attr("transform"));
                            return {
                                x: t.attr("x") + tr[0],
                                y: t.attr("y") + tr[1]
                            };
                        }
                    })
                    .on('drag', dragLegend)
                    .clickDistance(5)
            );
            
            function getTranslation(transform) {// https://stackoverflow.com/questions/38224875/replacing-d3-transform-in-d3-v4
                var g = document.createElementNS("http://www.w3.org/2000/svg", "g");
                g.setAttributeNS(null, "transform", transform);
                var matrix = g.transform.baseVal.consolidate().matrix;
                return [matrix.e, matrix.f];
            }
            
            function dragLegend(d){
                
                const d3event = d3.getEvent();
                
                var theLegend = d3.select(this);
                var extent = sankey.extent();
               
                if (d3event.x < extent[0][0]) {// prevent dragging left of svg
                    d3event.x = extent[0][0];
                }
                
                if (d3event.x > (extent[1][0] - legendDims.width)) {// prevent dragging right of svg
                    d3event.x = (extent[1][0] - legendDims.width);
                }
                
                if (d3event.y < extent[0][1]) {// prevent dragging top of svg
                    d3event.y = extent[0][1];
                }
                
                if (d3event.y > (extent[1][1] - legendDims.height)) {// prevent dragging bottom of svg
                    d3event.y = (extent[1][1] - legendDims.height);
                }
                
                theLegend
                    .attr("transform", function(){
                        return "translate("+ (d3event.x) + "," + (d3event.y) + ")";
                    });
                
            }
            
        }
        
        function dragmove(d) {
        
            var node = d3.select(this);
            var rect = node.select("rect");
        
            var rectX = rect.attr("x");
            var rectY = rect.attr("y");
            
            var extent = sankey.extent();
            const d3event = d3.getEvent();
            
            if (d.x0 + d3event.dx >= extent[0][0] && d.x1 + d3event.dx <= extent[1][0]) {// prevent dragging left or right of svg
                d.x0 = d.x0 + d3event.dx;
                d.x1 = d.x1 + d3event.dx;
            }
            
            if (d.y0 + d3event.dy >= extent[0][1] && d.y1 + d3event.dy <= extent[1][1]) {// prevent dragging top or bottom of svg
                d.y0 = d.y0 + d3event.dy;
                d.y1 = d.y1 + d3event.dy;
            }
            
            var xTranslate = options.link.arrows ? d.x0 + options.link.arrows.directedOffset - rectX : d.x0 - rectX;
            var yTranslate = d.y0 - rectY;
            
            node.attr("transform", 
                "translate("+ (xTranslate) + "," + (yTranslate) + ")");
            
            sankey.update(data);
            linkPaths.attr("d", function(d){
                return d.path;
            });
            
            if (options.link.arrows) {
                linkArrowHeads.attr('points', options.link.arrows.calcArrowHeadPoints);
                linkArrowTails.attr('points', options.link.arrows.calcArrowTailPoints);
            }
            
            if (options.link.animatedDashes) {
                animatedDashesPaths.attr('d', function(d){
                    return d.path;
                });
            }
            
        }
        
    }

    function chart() {

    }

    chart.data = function(data) {
        
        updateDimensions();

        processedRelations = processRelations(data);

        svg 
            .attr("width", options.dimensions.container.width)
            .attr("height", options.dimensions.container.height);

        sankey.extent([
            [1, 1],
            [options.dimensions.sankey.width, options.dimensions.sankey.height]
        ]);
        graphRenderData = sankey(processedRelations);
        buildGraph(graphRenderData);
    };

    chart.opts = function(opts) {
        
        options = merge({}, options, opts);

        sankey = d3.sankey()
            .nodeId(options.node.value)
            .nodeWidth(options.link.arrows ? options.node.width + (options.link.arrows.directedOffset * 2) : options.node.width)
            .nodePadding(options.node.padding)
            .nodePaddingRatio(0.1)
            .extent([[1, 1], [options.dimensions.sankey.width, options.dimensions.sankey.height]]);

    };

    chart.resize = function() {
        updateDimensions();

        svg 
            .attr("width", options.dimensions.container.width)
            .attr("height", options.dimensions.container.height);
        
        sankey.extent([
            [1, 1],
            [options.dimensions.sankey.width, options.dimensions.sankey.height]
        ]);

        graphRenderData = sankey(processedRelations);
        buildGraph(graphRenderData);

    };

    return chart;

}

function PhSankeyComponent({
    data,
    opts,
    pic,
    template
}) {

    const [ vis, setVis ] = useState(null);
    const sankeySVGElemRef = useRef(null);

    useEffect(() => {
        if (!vis) {
            setVis(() => phSankey({elem: sankeySVGElemRef.current}));
        }
    }, [vis, setVis]);

    useEffect(()=>{
        
        if (vis && opts && pic) {
            vis.opts({...opts, pic});
        }
        if (vis && data) {
            vis.data(data);
        }
    }, [data, opts, vis, pic]);

    useEffect(() => {
        var resizeDisposal;
        var resizeTimer;
        if (template && vis) {
            resizeDisposal = template.contentResize(function(){
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() {
                    // Run code here, resizing has "stopped"
                    vis.resize();
                }, 250);
            });
        }

        return function() {
            if (resizeDisposal) {
                resizeDisposal();
            }
        };
    }, [template, vis]);

    return (<>
        <svg id="sankeysvg" ref={sankeySVGElemRef}></svg>
    </>);
}

angular.module(moduleName, [
    pic, template
])
.component(componentName, react2angular(PhSankeyComponent, ['data', 'opts'], ['pic','template']));

export {
    phSankey,
    PhSankeyComponent,
    moduleName,
    componentName
};