import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./modalCommunity.scss";

/*
    function that takes the raw text and the current community to return useful
    output data for validation and determining output as well as final npi list
*/
function validateInput(textAreaStr, comm) {
    
    //empty default object to return
    const retObj = {
        invalidNpis: [],
        validNpis: [],
        sharedNpis: [],
        commNpis: [],
        inputSet: new Set(),
        commSet: new Set(),
        status: 'empty'
    };
    
    if (comm === undefined) {
        retObj.status = 'no community';
    } else {
        comm.npis.forEach(npi => {
            retObj.commSet.add(parseInt(npi, 10));
            retObj.commNpis.push(npi);
        });
    }
    
    if (!textAreaStr) {
        return retObj;
    }
    
    const npis = textAreaStr.split(/[ ,\n]+/);
    
    for (let i = 0; i < npis.length; i++) {
        let inputSegment = npis[i];
        inputSegment = inputSegment.trim().replace(/\s/g, '');
        if (inputSegment === '') { // tolerate empty strings
            continue;
        }
        if (/^[0-9]{10}$/.test(inputSegment)) { // check if 10 numeric digits
            // Luhn check
            var npi = '80840' + inputSegment;
            let sum = 0, pty = npi.length % 2;
            for (var n = npi.length - 1; n >=0; n--) {
                var d = parseInt(npi.charAt(n));
                if (n % 2 == pty) { d *= 2; }
                if (d > 9) { d -= 9; }
                sum += d;
            }
            if (!(sum % 10 == 0)) {
                retObj.invalidNpis.push(parseInt(inputSegment, 10));
            } else {
                retObj.inputSet.add(parseInt(inputSegment, 10));
                retObj.validNpis.push(parseInt(inputSegment, 10));
            }
        } else {
            retObj.invalidNpis.push(inputSegment);
        }
    }
    
    Array.from(retObj.commSet.intersection(retObj.inputSet)).forEach(npi => retObj.sharedNpis.push(npi));
    
    
    
    if (retObj.invalidNpis.length === 0 && retObj.validNpis.length > 0) {
        retObj.status = 'valid';
    }
    
    if (retObj.commSet.union(retObj.inputSet).size > 500) {
        retObj.status = 'Over 500';
    }
    
    if (retObj.invalidNpis.length > 0) {
        retObj.status = 'invalid';
    }
    
    return retObj;
}

export function CommunityAddBulkModal(props) {

    const { show, onClose, updateMembers, api, community, pic } = props;
    const [npiList, setNpiList] = useState('');
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (show) { }
    }, [show]);

    function addBulk() {
        if (npiList.length > 0) {
            setUpdating(true);

            let currentData = validateInput(npiList, community);
            let editableCommunity = community;
            
            editableCommunity.npis = Array.from(currentData.commSet.union(currentData.inputSet));

            editableCommunity.Update({ editableCommunity }, { api: api, pic: pic })
                .then(function() {
                    updateMembers();
                    onClose();
                    setUpdating(false);
                });
        }
    }
    
    return (
        <div className="community-modal">
            <Modal show={show} onHide={onClose} dialogClassName="modal-lg-community">
                <Modal.Header closeButton>
                    <h3 className="modal-title" id="modal-title-search">Add Bulk Community Members</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">Enter NPIs to add to the {community.name} community</label>
                        <textarea
                            rows="15"
                            className="form-control"
                            value={npiList}
                            onChange={(e) => setNpiList(e.target.value)}
                            name="newNpis"
                            placeholder="Enter NPIs seperated by a comma, line break, or a space."
                            required
                        />
                        {!npiList && <div style={{ marginTop: '0.25rem', color: '#dc3545' }}>This is a required field.</div>}
                        {validateInput(npiList, community).invalidNpis.length > 0 && <div style={{ marginTop: '0.25rem', color: '#dc3545' }}>
                            <b>{validateInput(npiList, community).invalidNpis.join(', ')}</b> {validateInput(npiList, community).invalidNpis.length > 1 ? 'are' : 'is an'} invalid NPI. Only valid NPI numbers permitted.
                        </div>}
                        {updating === false && validateInput(npiList, community).sharedNpis.length > 0 &&
                            <div style={{ marginTop: '0.25rem', color: '#dc3545' }}>
                                The following NPIs already exist in the community and will not be added: <b>{validateInput(npiList, community).sharedNpis.join(', ')}</b>
                            </div>
                        }
                        {validateInput(npiList, community).status === 'Over 500' && <div style={{ marginTop: '0.25rem', color: '#dc3545' }}>Limited to 500 NPIs, including current community members.</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => addBulk()}
                            disabled={validateInput(npiList, community).status !== 'valid' || updating}
                        >
                            {updating ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Add'}
                        </button>
                        <button
                            className="btn btn-warning"
                            type="button"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}