import React, { useEffect, useState, useRef } from 'react';
import ReactDom from 'react-dom';
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';


import { useConfig, useApi, usePic, useTourManager, useLogging } from '../../services/servicesContext';

export function SharedVisitsComponent({ opts, notify, download, tour, sharePhTableData, CommunityList }) {

    const apiService = useApi();
    const picService = usePic();
    const tourManager = useTourManager();
    const logging = useLogging();
    const tableRef = useRef(null);

    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const [ modalOpen, setModalOpen ] = useState(false);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    
    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.sharedvisits`
        });
        
        async function init() {
            try {
                setLoading(true);
                
                tourManager.createTour([
                    {
                        id: 1,
                        title: 'Shared Visits Report - 1/12',
                        text: 'This report shows where patients end up within 30 days before/after an acute care episode, helping you understand where mutually beneficial relationships with pre- and post-acute care facilities and physicians can be developed.<br><br>Use left/right arrow keys to navigate.',
                    },
                    {
                        id: 2,
                        attachTo: { element: '.tourscript-name', on: 'bottom'},
                        title: 'Shared Visits Report - 2/12',
                        text: 'For example, this provider saw...',
                    },
                    {
                        id: 3,
                        attachTo: { element: '.tourscript-uniquefrom', on: 'bottom'},
                        title: 'Shared Visits Report - 3/12',
                        text: 'this many patients...',
                    },
                    {
                        id: 4,
                        title: 'Shared Visits Report - 4/12',
                        text: `after ${npis.name.display}`,
                    },
                    {
                        id: 5,
                        attachTo: { element: '.tourscript-sharedfrom', on: 'bottom'},
                        title: 'Shared Visits Report - 5/12',
                        text: `which equaled this many total visits...`,
                    },
                    {
                        id: 6,
                        attachTo: { element: '.tourscript-sameday', on: 'bottom'},
                        title: 'Shared Visits Report - 6/12',
                        text: 'and this many same day visits.',
                    },
                    {
                        id: 7,
                        attachTo: { element: '.tourscript-uniqueto', on: 'bottom'},
                        title: 'Shared Visits Report - 7/12',
                        text: 'And this many patients...',
                    },
                    {
                        id: 8,
                        title: 'Shared Visits Report - 8/12',
                        text: `before ${npis.name.display}`,
                    },
                    {
                        id: 9,
                        attachTo: { element: '.tourscript-sharedto', on: 'bottom'},
                        title: 'Shared Visits Report - 9/12',
                        text: 'which equaled this many total visits...',
                    },
                    {
                        id: 10,
                        attachTo: { element: '.content-container thead', on: 'bottom'},
                        title: 'Shared Visits Report - 10/12',
                        text: 'You can sort on any column by simply clicking on the header name.',
                    },
                    {
                        id: 11,
                        attachTo: { element: '.content-container .table-controls-input .filter-box', on: 'bottom'},
                        title: 'Shared Visits Report - 11/12',
                        text: 'Also, you can filter results here by searching for any value in the row.',
                    },
                    {
                        id: 12,
                        attachTo: { element: '.content-container .table-controls-input #tourscript-buttons', on: 'bottom'},
                        title: 'Shared Visits Report - 12/12',
                        text: 'Use this button to download the data in the table.',
                    }
                ]);
                
                let data = await apiService.Relations(npis.npi, false, { affiliations: 'all' });
                
                if (data.length === 0) {
                    console.log('No relations found for provider');
                    setLoading(false)
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No data found for provider.',
                        delay: 30000
                    });
                }
                
                var hash = data.reduce(function(hash, rel) {
                    var dir = rel.source.npi == npis.npi ? 'target' : 'source';
                    var npi = rel[dir].npi;
                    if ( !hash[npi] ) {
                        hash[npi] = {
                            [dir]: Object.assign({}, rel.values)
                        };
                    } else {
                        hash[npi][dir] = Object.assign({}, rel.values);
                    }
                    hash[npi].sameday = hash[npi].sameday || rel.values.sameday;
                    return hash;
                }, {});

                var datums = Object.keys(hash).map(function(npi) {
                    return hash[npi];
                });
                
                var nullSubstitute = {
                    sameday: 0,
                    shared: 0,
                    unique: 0
                };
                
                datums = datums.map(function(datum) {
                    if (datum.target && !datum.source) {
                        datum.source = Object.assign({}, nullSubstitute);
                    } else if (datum.source && !datum.target) {
                        datum.target = Object.assign({}, nullSubstitute);
                    }
                    return datum;
                });

                var promises = Object.keys(hash).map(function(npi) { return apiService.GetProvider(npi); });
                
                Promise.all(promises).then(function(providers) {
                    providers.forEach(function(provider) {
                        hash[provider.npi].provider = provider; 
                    });
                      datums = datums.map(function(datum) {
                          datum.communities = picService(datum.provider.npi);
                          return datum;
                      });

                      const data = datums.map((datum) => {
                          return {
                                "entitytype" : datum.provider.entitytype,
                                "phone" : datum.provider.location.phone_number,
                                "taxonomy_code": datum.provider.taxonomy.code,
                                "address" : datum.provider.location.address,
                                "city" : datum.provider.location.city,
                                "state" : datum.provider.location.state,
                                "county" : datum.provider.location.county,
                                "zip" : datum.provider.location.postal_code,
                                "npi" : datum.provider.npi,
                                "communities" : datum.communities,
                                "provider" :  datum.provider.name.display,
                                "hospital_affiliations" : datum.provider.hospital_affiliations,
                                "hospital_affiliations_names" : datum.provider.hospital_affiliations?.map(affiliate => { return `${affiliate.hospitalname} (${affiliate.hospitalnpi})` }).join('|'),
                                "group_affiliations" : datum.provider.group_affiliations,
                                "group_affiliations_names" : datum.provider.group_affiliations?.map(affiliate => { return `${affiliate.groupname} (${affiliate.groupnpi})` }).join('|'),
                                "specialty" : datum.provider.taxonomy.classification + (datum.provider.taxonomy.specialization ? " (" + datum.provider.taxonomy.specialization + ")" : ""),
                                "shared_visits_from": datum.target.shared,
                                "unique_patients_from": datum.target.unique,
                                "sameday": datum.sameday,
                                "unique_patients_to": datum.source.unique,
                                "shared_visits_to": datum.source.shared
                          };
                        });
                      setLoading(false);
                      setData(data);

                }).catch(err => console.error(err));

            } catch(err) {
                setLoading(false);
                notify.error({text: 'Error requesting relations'});
                console.error('error requesting relations', err);
            }
        }

        init();
        return () => {
            tourManager.clearTour();
        };
    }, [npis]);

    const reportConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col16",
                    "accessor": "entitytype",
                    "content": "Entity Type",
                    "headerTooltip": "Row provider entity type",
                    "defaultVisibilty": false,
                    "export": false,
                    "sortable": true,
                    "template": content => content && ((content === '2') ? 'Organization' : 'Physician' )
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 16
                    }
                },

                {
                    "header": {
                        "id": "col17",
                        "accessor": "taxonomy_code",
                        "content": "Taxonomy Code",
                        "headerTooltip": "Row provider taxonomy code",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                },
                {
                    "header": {
                        "id": "col15",
                        "accessor": "address",
                        "content": "Address",
                        "headerTooltip": "Row provider address",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col14",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "Row provider city",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "Row provider state",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "county",
                        "content": "County",
                        "headerTooltip": "Row provider county",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "zip",
                        "content": "ZIP",
                        "headerTooltip": "Row provider postal code",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col18",
                        "accessor": "phone",
                        "content": "Phone",
                        "headerTooltip": "Row provider phone number",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": function(content) { const formatPhoneNum = content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                },
                {
                    "header": {
                        "id": "col1",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.zip,
                                  'phone_number': phone,
                                  'city': rowDataObject.city,
                                  'state': rowDataObject.state
                                 },
                                'name': {'display' : rowDataObject.provider }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                    
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) {    
                            if (content && content.length === 0) return;                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                };
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>);  
                            });
                            return arrContent;
                       }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 2                    
                    }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "provider",
                        "content": "Provider",
                       "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 3
                    }
                },
                {
                    "header": {
                        "id": "col19",
                        "accessor": "hospital_affiliations_names",
                        "content": "Hospital Affiliations",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) {
                            return organizeAffiliates(rowDataObject, 'Hospital');  
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": "link",
                            "style": "",
                            "id": 19
                    }
                },
                {
                    "header": {
                        "id": "col20",
                        "accessor": "group_affiliations_names",
                        "content": "Group Affiliations",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) {
                            return organizeAffiliates(rowDataObject, 'Group');  
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": "link",
                            "style": "",
                            "id": 20
                    }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "specialty",
                        "content": "Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "shared_visits_from",
                        "content": "Shared Visits From",
                        "headerTooltip": "Total visits to the row provider from report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sharedfrom'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "unique_patients_from",
                        "content": "Unique Patients From",
                        "headerTooltip": "Unique patients to the Provider from the report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-uniquefrom'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "headerTooltip": "Same day visits for the Provider and report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sameday'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                    }
                },   
                {
                    "header": {
                        "id": "col8",
                        "accessor": "unique_patients_to",
                        "content": "Unique Patients To",
                        "headerTooltip": "Unique patients from the Provider to the report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": 'desc',
                        "template": content => <span className='tourscript-uniqueto'>{content && content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "shared_visits_to",
                        "content": "Shared Visits To",
                        "headerTooltip": "Total visits from the Provider to report provider",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content && <span className='tourscript-sharedto'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                   }
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        reportTitle: `Referrals Report for ${npis && npis.name && npis.name.display}`,
        localStorageName: 'app.phy.reports.sharedvisits.v4'
        };
    };
    
    const organizeAffiliates = (rowDataObject, column) => {
        const closeModal = (currentState) => {
            setModalOpen(currentState);
        };

        let displayContent = '';
        let affiliateNames = [];
        let columnType;
        
        switch (column) {
            case 'Group':
                columnType = rowDataObject.group_affiliations;
                break;
            case 'Hospital':
                columnType = rowDataObject.hospital_affiliations;
                break;
            default:
                return undefined;
        }
        
        if (columnType && rowDataObject.entitytype == 1) {
            displayContent = columnType.map((affiliate) => {
                if (column == 'Hospital') {
                    return {
                        'name' : affiliate.hospitalname,
                        'npi' : affiliate.hospitalnpi,
                        'provider_id' : affiliate.hospitalproviderid
                    };
                }
                else {
                    return {
                        'name' : affiliate.groupname,
                        'npi' : affiliate.groupnpi,
                        'provider_id' : affiliate.groupproviderid
                    };
                }
            });

            for (let i = 0; i < 5 && i < displayContent.length; i++) {
                const splitName = displayContent[i].name.split(' ');
                let shortenedName = '';
                if (splitName.length <= 2) {
                    shortenedName = splitName.join(' ') + '\n';
                }
                else {
                    shortenedName = splitName[0] + ' ' + splitName[1] + '...\n';
                }
                
                const npi_popover = {
                    npi : displayContent[i].npi,
                    name : displayContent[i].name,
                    api : apiService
                };
                
                affiliateNames.push(<PopoverDetails {...npi_popover}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${displayContent[i].npi}/home`} style={{ 'whiteSpace' : 'pre-wrap' }}>{shortenedName}</Link></PopoverDetails>);
            }
            if (displayContent.length > 5) {
                affiliateNames.push(<div onClick={() => setModalOpen(true)} style={{ 'whiteSpace' : 'pre-wrap', 'cursor' : 'pointer' }}>{`see ${displayContent.length - 5} more...\n`}</div>);
                affiliateNames.push(
                    <AffiliatesModal
                        open={modalOpen}
                        download={download}
                        onClose={() => closeModal(!modalOpen)}
                        title={`${column} Affiliates for ${rowDataObject.provider}`}
                        providerInfo={rowDataObject}
                        affiliates={displayContent}
                        loading={loading}
                    />
                );
            }
        }
        return affiliateNames;
    };
  
const updateCommunityList = (currentList) => {
    const fullList = data || [];
    if (currentList.length < fullList.length) {
        CommunityList.update({listVal: currentList, altListVal: fullList});
    } else {
        CommunityList.update({listVal: currentList});
    }
};

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} needCommUpdate={true} CommunityList={CommunityList}/>  : null}
    </>
  );
}

function AffiliatesModal(props) {
    const [ affiliatesModalData, setAffiliatesModalData ] = useState(null);
    const apiService = useApi();
    const picService = usePic();
    
        if (!props?.open) return null;
        
        const modal_styles = {
            position : 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            zIndex: 1000,
            maxHeight: '100vh',
            minWidth: '960px',
            overflowY: 'auto',
        };

        const overlay_style = {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, .7)',
            zIndex: 1000
        };
        
        useEffect(() => {
            async function init() {
                try {
                    const affiliatePromises = props.affiliates.map(function(affiliate) { return apiService.GetProvider(affiliate.npi); });
                    const affiliates = await Promise.all(affiliatePromises);
                    const affiliatesNpi = affiliates.map((affiliateinfo) => {
                        const formatPhoneNum = affiliateinfo && affiliateinfo.location && affiliateinfo.location.phone_number && affiliateinfo.location.phone_number.toString() || '';
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`;
                        return {
                            'npi' : affiliateinfo.npi,
                            'postal_code' : affiliateinfo.location.postal_code,
                            'phone_number': phone,
                            'city': affiliateinfo.location.city,
                            'state': affiliateinfo.location.state,
                            'provider' : affiliateinfo.name.display,
                            'communities': picService(affiliateinfo.npi)
                        };
                    });
                    setAffiliatesModalData(affiliatesNpi);
                }
                catch(err) {
                    console.log(err);
                }
            }
            init();
        }, []);
    
    const affiliatesModalConfig = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col1",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) {
                            const npi_popover = {
                                npi : content,
                                name : rowDataObject.provider,
                                api : apiService
                            }; 
                            return <PopoverDetails {...npi_popover}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>;  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                    
                    }
                 },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "provider",
                        "content": "Provider",
                      "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>;
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                          "content": {},
                            "style": "",
                            "id": 2
                    }
                },
                                {
                    "header": {
                        "id": "col3",
                        "accessor": "phone_number",
                        "content": "Phone Number",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 3                    
                    }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "city",
                        "content": "City",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "state",
                        "content": "State",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "postal_code",
                        "content": "Postal Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { 
                            if (content && content.length === 0) return;                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                };
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>);  
                            }); 
                            return arrContent;
                      }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 7                    
                    }
                }
               ],
        configure : false,
        configureButton: false,
        showSelected: false,
        filterInput: true,
        csvdownload: false,
        pagination: true,
        reportTitle: `Affiliates for ${props.providerInfo.provider}`,
        localStorageName: 'app.phy.reports.modalAffiliates.v1'
        };
    };
    
    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.onClose}>×</button>
                <h3 className="modal-title" id="modal-title">{props.title}</h3>
            </div>
            <div className="modal-body" style={{margin: 'auto'}}>
                <div className="loading-lg" style={{ "display" : (props.loading ? 'inline' : 'none' )}}></div>
                { (affiliatesModalData) ? <PhtableComponent download={props.download} data={affiliatesModalData} reportconfig={affiliatesModalConfig}></PhtableComponent>  : null}
            </div>
            <div className="modal-footer">
                <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </>, document.body);
    }
