import React, { useEffect, useState } from 'react';
// import ReactDom from 'react-dom/client';
import merge from 'lodash/merge';
// import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom/client';
import { createPortal } from 'react-dom';
import community from './community';

import { toast } from 'react-toastify';

export class Community {
    
    entityTypeClassName = 'Community'
    
    constructor (data) {
        if (data && data.insert_date) {
            try {
                data.insert_date = Date.parse(data.insert_date);
            } catch (ex) { }
        }
        merge(this, data);
    }

    valueOf () {
        return this.id || this.name;
    }

    toString () {
        return this.name;
    }

    serialize () {
        return {
            id: this.id,
            name: this.name,
            abbr: this.abbr,
            npis: this.npis.slice(0),
            owner: this.owner,
            is_public: this.is_public,
            watched: this.watched
        };
    }

    type() {
        return Promise.resolve(this).then((community) => {
            return community.entitytype;
        })
    }

    Copy(deps) {
        //deps must include api and pic
        var community = this;
        var newCommunity = new Community(community);
        newCommunity.id = null;
        newCommunity.name = 'Copy of ' + newCommunity.name;
        
        return newCommunity.Update(undefined, deps);
    };

    Delete(deps) {
        let community = this;
        const modalContainer = document.createElement('div');
        modalContainer.setAttribute('id','community-delete-modal');
        document.body.appendChild(modalContainer);
        const modalRoot = ReactDom.createRoot(modalContainer);
        function removeModal() {
            modalRoot.unmount();
            modalContainer.remove();
        }

        modalRoot.render(
            <CommunityDeleteModal
                community={community}
                api={deps.api}
                pic={deps.pic}
                updateList={deps.updateList}
                removeDeletedItem={deps.removeDeletedItem}
                close={removeModal}
            />
        );
    }

    EditName(deps) {
        let community = this;
        const modalContainer = document.createElement('div');
        modalContainer.setAttribute('id','community-edit-name-modal');
        document.body.appendChild(modalContainer);
        const modalRoot = ReactDom.createRoot(modalContainer);
        function removeModal() {
            modalRoot.unmount();
            modalContainer.remove();
        }

        modalRoot.render(
            <CommunityEditNameModal
                community={community}
                updateList={deps.updateList}
                pic={deps.pic}
                api={deps.api}
                close={removeModal}
            />
        );
    }

    RemoveMember(obj,deps,listUpdate) {
        let community = this;
        const modalContainer = document.createElement('div');
        modalContainer.setAttribute('id','community-remove-member-modal');
        document.body.appendChild(modalContainer);
        const modalRoot = ReactDom.createRoot(modalContainer);
        function removeModal() {
            if (deps.callback) {
                deps.callback();
            }
            modalRoot.unmount();
            modalContainer.remove();
        }

        modalRoot.render(
            <RemoveMemberModalComponent
                community={community}
                provider={obj.provider}
                pic={deps.pic}
                api={deps.api}
                close={removeModal}
                listUpdate={listUpdate}
            />
        );
    }

    async ResolveMembers (api) {
        const community = this;
        community._npis = community.npis.slice(0);
        const npis = await Promise.all(
            community.npis.map(function(npi) {
                return api.GetProvider(npi);
            })
        );
        return npis;
    }

    async Update(obj, deps) {
        // deps must include api and pic
        var community = this;

        try {

            if (obj) {
                Object.assign(community, obj);
            }
            community.updating = true;
            const updatedCommunity = await deps.api.updateCommunity(community);
            community.updating = false;

            toast(`${updatedCommunity.name} Updated Successfully`, { type: toast.TYPE.INFO, autoClose: 3000 });

            await deps.pic.Update();
            // $rootScope.$broadcast('recentlySelectedChange', 'community');//used to properly update sidebar menu and breadcrumb menus after modifying community
            return updatedCommunity;

        } catch (err) {
            community.updating = false;

            toast(`There was a problem updating ${community.name}`, { type: toast.TYPE.ERROR, autoClose: 3000 });
            
            return err;
        }

    }
}

function CommunityDeleteModal(props) {
    
    const [ processing, setProcessing ] = useState(false);

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        width: '600px',
        overflowY: 'auto',
    };

    const overlay_style = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: 1000
    };

    function confirmDelete() {
        setProcessing(true);
        props.api.deleteCommunity(props.community)
        .then(res => {
            props.pic.Update();
            props.removeDeletedItem(props.community);
            setProcessing(false);
            toast(`${props.community.name} has been removed from your communities.`, {type: toast.TYPE.INFO, autoClose: 3000 });
            props.close();
        }, err => {
            setProcessing(false);
            toast(`There was a problem removing ${props.community.name}.`, {type: toast.TYPE.ERROR, autoClose: 3000 });
            console.error('error deleting community', err);
        })
    }

    return <>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.close}>x</button>
                <h3 className="modal-title" id="modal-title">Delete Confirmation</h3>
            </div>
            <div className="modal-body">
                Are you sure you want to delete {props.community.name}?
            </div>
            <div className="modal-footer"> 
                <button style={{ 'margin': '5px' }} className="btn btn-primary" onClick={confirmDelete}>
                    { processing
                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        : 'Yes'
                    }
                </button>
                <button style={{ 'margin': '5px' }} className="btn btn-warning" onClick={props.close}>No</button>
            </div>
        </div>
    </>;
}

function CommunityEditNameModal(props) {

    const [ processing, setProcessing ] = useState(false);
    const [ editableCommunityName, setEditableCommunityName ] = useState(null);
    const [ editableCommunityAbbr, setEditableCommunityAbbr ] = useState(null);

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        width: '600px',
        overflowY: 'auto',
    };

    const overlay_style = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: 1000
    };

    useEffect(() => {
        setEditableCommunityName(props.community.name);
        setEditableCommunityAbbr(props.community.abbr);
    }, []);

    function confirmEdit() {
        setProcessing(true);
        const updatedCommunity = {...props.community, name: editableCommunityName, abbr: editableCommunityAbbr};
        props.community.name = editableCommunityName;
        props.community.abbr = editableCommunityAbbr;
        props.community.Update(undefined, {pic: props.pic, api: props.api})
        .then(success => {
            setProcessing(false);
            props.updateList();
            props.close();
        }, err => {
            setProcessing(false);
            console.error('error using community.update() to edit name/abbr', err);
            props.close();
        });
    }

    return <>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.close}>x</button>
                <h3 className="modal-title" id="modal-title">Edit Community</h3>
            </div>
            <div className="modal-body">
                { props.community.name && <div className={`form-group ${!editableCommunityName && 'has-error'}`}>
                    <label
                        htmlFor="name"
                        className="control-label"
                    >Community Name</label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editableCommunityName || ''}
                        onChange={e => setEditableCommunityName(e.target.value)}
                        required
                    />
                    { !editableCommunityName && <span className="help-block" >This is a required field.</span>}
                </div>}
                
                { props.community.abbr && <div className={`form-group ${(!editableCommunityAbbr || editableCommunityAbbr.length > 3) && 'has-error'}`}>
                    <label
                        htmlFor="abbr"
                        className="control-label"
                    >Community Abbreviation</label>
                    <input
                        type="text"
                        className="form-control"
                        name="abbr"
                        value={editableCommunityAbbr || ''}
                        onChange={e => setEditableCommunityAbbr(e.target.value)}
                        required
                    />
                    { !editableCommunityAbbr && <span className="help-block">This is a required field.</span>}
                    {' '}
                    { editableCommunityAbbr?.length > 3 && <span className="help-block">Maximum 3 characters.</span>}
                </div>}
            </div>
            <div className="modal-footer"> 
                <button
                    disabled={(
                        !editableCommunityName ||
                        !editableCommunityAbbr ||
                        editableCommunityAbbr?.length > 3 ||
                        (editableCommunityName === props.community.name  && editableCommunityAbbr === props.community.abbr)
                    )}
                    style={{ 'margin': '5px' }}
                    className="btn btn-primary"
                    onClick={e => confirmEdit()}
                >
                    { processing
                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        : 'Edit'
                    }
                </button>
                <button
                    style={{ 'margin': '5px' }}
                    className="btn btn-warning"
                    onClick={props.close}
                >Cancel</button>
            </div>
        </div>
    </>;
}

export function RemoveMemberModalComponent(props) {

    const [ processing, setProcessing ] = useState(false);
    
    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        minWidth: '960px',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    };

    function removeCommunityMember() {
        setProcessing(true);
        props.community.npis.splice(props.community.npis.indexOf(props.provider.npi), 1);
        props.community.Update(props.community, {api: props.api, pic: props.pic})
        .then(function(updatedCommunity) {
            setProcessing(false);
            if(props.listUpdate){
                props.listUpdate();
            }
            props.close();
        }, function(err) {
            setProcessing(false);
            console.error('error removing member', err);
            props.close();
        });
        
    };
    
    return /* ReactDom. */createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <h3 className="modal-title" id="modal-title">Delete Confirmation</h3>
            </div>
            <div className="modal-body ">
                <p>Are you sure you want to remove {props.provider.name.display} from {props.community.name}?</p>
            </div>
            <div className="modal-footer">
                <button style={{ 'margin': '5px' }} className="btn btn-primary" onClick={removeCommunityMember} disabled={processing}>
                    { processing
                        ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                        : 'Yes'
                    }
                </button>
                <button style={{ 'margin': '5px' }} className="btn btn-warning" onClick={props.close}>No</button>
            </div>
        </div>
    </>, document.body);
}