import tourConfig from './tourConfig';

export default ['$scope', 'api', '$q', '$state', '$window', 'notify', 'tour','$timeout', '$templateCache', function($scope, api, $q, $state, $window, notify, tour, $timeout, $templateCache) {
    var $ctrl = this;
    
    var options = {
        node: {
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else { // Community AKA 'Others'
                    return d.name; 
                }
            }
        }
    };
    
    if ( !$templateCache.get('ng-template/sankeyToolTip.html') ) {//in standalone, default sankey tooltip template isn't present
        console.log('no tooltip template detected');
        options.toolTip = {
            template: $templateCache.get('ng-template/sankeyGeneralFlowToolTip.html')
        };
    } 
    
    $ctrl.$onInit = function() {
        
        if ($ctrl.reportSankey) {
            $ctrl.reportSankey.options(options);
        }
        
        if ($ctrl.opts) {
            angular.merge(options, $ctrl.opts)
        }
        
        tour.setScript(tourConfig($timeout), $scope);
        
        var relationsPromise;
        if ($ctrl.npis) {
            relationsPromise = api.Relations($ctrl.npis[0].npi, 'outbound', { classification: 'General Acute Care Hospital' });
        } else if ($ctrl.community) {
            relationsPromise = api.CommunityRelations($ctrl.community.id, 'outbound', { classification: 'General Acute Care Hospital' });
        }
         
        // .then(function(data) {
        relationsPromise
        .then(function(data) {
            if (data.length === 0) {
                console.log('No Provider General Flow data found');
                notify.alert({
                    title: 'NO DATA',
                    text: 'No Provider General Flow data found.',
                    delay: 30000
                });
            }
            
            var sortedData = data.sort(function(a, b) {
                return b.values.shared - a.values.shared;
            });
            
            var finalSortedDataLookup = sortedData.reduce((acc, relation) => {
                if (!acc[relation.target.npi]) {
                    acc[relation.target.npi] = {
                        target: relation.target,
                        values: relation.values
                    };
                } else {
                    acc[relation.target.npi].values.sameday += relation.values.sameday;
                    acc[relation.target.npi].values.shared += relation.values.shared;
                    acc[relation.target.npi].values.unique += relation.values.unique;
                }
                return acc;
            },{});
            
            var finalData = Object.keys(finalSortedDataLookup).map(key => {
                return new api.Relation({
                    source: $ctrl.community,
                    target: finalSortedDataLookup[key].target,
                    values: finalSortedDataLookup[key].values
                });
            });
            
            $scope.loaded = true;
            
            if($ctrl.reportSankey) {
                $ctrl.reportSankey.data(finalData);
            } else {
                $scope.options = options;
                $scope.data = finalData;
            }
        }, function(err){
            console.log('Error fetching Provider General Flow data');
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider General Flow data.',
                delay: 30000
            });
            if($ctrl.reportSankey) {
                $ctrl.reportSankey.data([]);//need to send an empty array, so the sankey loading indicator stops
            }
            
        });
    };
    
    function bindSankeyMouseClick($scope){
        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
            if (datum instanceof api.Provider) {
                var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                if (newTabUrl) {// for standalone
                    $window.open(newTabUrl, '_blank');
                }
            }
        });  
    }
        
    bindSankeyMouseClick($scope);
}];