import tourConfig from './tourConfig';

export default ['$scope', 'api', '$q', '$state', '$window', 'notify', 'tour','$timeout', '$templateCache', function($scope, api, $q, $state, $window, notify, tour, $timeout, $templateCache) {
    var $ctrl = this;

    $scope.axis = 'shared';

    $scope.mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };

    $ctrl.toggleAxis = function(property) {
        var optionsClone = { ...$ctrl.options };

        optionsClone.link = {
            value: function(d) {
                return d.values[property];
            }
        };

        //preserves $ctrl.opts.toolTip.template when used in standalone
        angular.merge(optionsClone.toolTip, {
            units: $scope.mapping[property].units,
            value: function(d) {
                return d.values[property];
            }
        });

        optionsClone.node.sortAxis = $scope.mapping[property].sortAxis;
        $ctrl.options = optionsClone;
        renderData();
    };

    function renderData() {
        var data = $ctrl.apiData.map(relation => {
            relation.target = {
                side: 'right',
                __proto__: relation.target
            }
            return relation;
        });

        var topN = data.sort(function (a, b) {
            if ($ctrl.options?.link?.value) {
                return $ctrl.options.link.value(b) - $ctrl.options.link.value(a);
            } else {
                return b.values[$scope.axis] - a.values[$scope.axis];
            }
        }).splice(0, 19);

        if (data.length > 0) {
            var others = data.reduce(function (rel, item) {
                rel.values.shared += item.values.shared;
                rel.values.sameday += item.values.sameday;
                rel.values.unique += item.values.unique;
                rel.target.members.push(item.target);

                return rel;

            }, new api.Relation({
                source: $ctrl.npis[0],
                target: new api.Community({ name: 'others', members: [] }),
                values: {
                    shared: 0,
                    sameday: 0,
                    unique: 0
                },
            }));

            topN.push(others);
        }

        $scope.loaded = true;

        if (!$ctrl.reportSankey) {
            $scope.options = $ctrl.options;
            $scope.data = topN;
        }
    }

    $ctrl.resetDropdown = function () {
        $scope.axis = 'shared';
        $ctrl.toggleAxis('shared');
    };
    
    $ctrl.$onInit = function() {

        $ctrl.options = {
            node: {
                label: function(d) {
                    if (d.entitytype == 2) {
                        return d.name.display + ' (' + d.npi + ')';
                    } else if (d.entitytype == 1) {
                        return d.name.primary.slice(0, 1) + '. ' + d.name.secondary + ' (' + d.npi + ')';
                    } else { // Community AKA 'Others'
                        return d.name;
                    }
                },
                sortAxis: 'totalShared'
            },
            link: {
                value: function(d) {
                    return d.values[$scope.axis];
                }
            },
            toolTip: {
                units: 'Shared Visits',
                value: function(d) {
                    return d.values[$scope.axis];
                }
            }
        };

        if (!$templateCache.get('ng-template/sankeyToolTip.html')) {//in standalone, default sankey tooltip template isn't present
            $ctrl.options.toolTip = {
                template: $templateCache.get('ng-template/sankeyGeneralFlowToolTip.html')
            };
        }
        
        tour.setScript(tourConfig($timeout), $scope);
        
        api.Relations($ctrl.npis[0].npi, 'outbound', { classification: 'General Acute Care Hospital' })
        .then(function(data) {
            if (data.length === 0) {
                console.log('No Provider General Flow data found');
                notify.alert({
                    title: 'NO DATA',
                    text: 'No Provider General Flow data found.',
                    delay: 30000
                });
            }

            $ctrl.apiData = data;
            
            if (!$ctrl.reportSankey) {
                $ctrl.toggleAxis('shared');
            }

        }, function(err){
            console.log('Error fetching Provider General Flow data');
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider General Flow data.',
                delay: 30000
            });
        });
    };
    
    function bindSankeyMouseClick($scope){
        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
            if (datum instanceof api.Provider) {
                var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                if (newTabUrl) {// for standalone
                    $window.open(newTabUrl, '_blank');
                }
            }
        });  
    }
        
    bindSankeyMouseClick($scope);
}];