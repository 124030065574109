import angular from 'angular';
import tourConfig from './tourConfig';
import template from '../../services/template';

export default [
    '$scope', 'pic', 'CommunityList', '$localStorage', 'leafletData', 'tour', 'api', 'template', '$timeout', '$q', 'affiliations',
    function($scope, pic, CommunityList, $localstorage, leafletData, tour, api, template, $timeout, $q, affiliations) {
        var $ctrl = this;
        $scope.pic = pic;
        var communities;
        var provider;
        $scope.originalCommunities = {};
            
        $ctrl.$onInit = function() {
            provider = $scope.provider = $ctrl.npis[0];
            
            $scope.entitytype;
            if (provider instanceof api.Organization) {
                $scope.entitytype = 'organization';
                
                affiliations.getHospitalAffiliations({
                    hospitalnpi: `${$scope.provider.npi}`
                })
                .then(hospitalAffiliates => {
                    return $q.all(hospitalAffiliates.map(affiliate => {
                        return api.GetProvider(affiliate.npi).then(provider => {
                            provider.source = affiliate.source;
                            return provider;
                        });
                    }));
                })
                .then(providers => {
                    $scope.hospitalAffiliates = providers;
                });
                
                affiliations.getGroupAffiliations({
                    groupnpi: `${$scope.provider.npi}`
                })
                .then(groupAffiliates => {
                    return $q.all(groupAffiliates.map(affiliate => {
                        return api.GetProvider(affiliate.npi).then(provider => {
                            provider.source = affiliate.source;
                            return provider;
                        });
                    }));
                })
                .then(providers => {
                    $scope.groupAffiliates = providers;
                });
                
            } else if (provider instanceof api.Physician) {
                $scope.entitytype = 'physician';
                
                affiliations.getAffiliations($scope.provider.npi)
                .then(data => {
                    return $q.all([data.hospitalAffiliations.then(hospitals => hospitals), data.groupAffiliations.then(groups => groups)]);
                })
                .then(data => {
                    $scope.hospitalAffiliations = data[0].map(provider => {
                        return Object.assign(provider, {
                            communities: provider.npi ? pic(provider.npi) : [],
                            ttm: $scope.ttm
                        });
                    });
                    $scope.groupAffiliations = data[1].map(provider => {
                        return Object.assign(provider, {
                            communities: provider.npi ? pic(provider.npi) : [],
                            ttm: $scope.ttm
                        });
                    });
                });
                
            }
            
            tour.setScript(tourConfig, $scope);
            
            $scope.pic = pic;
            
            $scope.reportOptionsMy = { 
                report: { 
                    name: 'My Communities for ' + provider.name.display
                },
                sort: {
                    column: 'name',
                    reverse: false
                },
                export: {
                    disable: true
                }
            };
            
            $scope.reportOptionsShared = { 
                report: { 
                    name: 'Shared Communities for ' + provider.name.display
                },
                sort: {
                    column: 'name',
                    reverse: false
                },
                export: {
                    disable: true
                }
            };
            
            $scope.reportOptionsHospital = { 
                pagination: {
                    disable: true
                },
                filterInput: {
                    disable: true
                },
                configure: {
                    disable: true
                },
                export: {
                    name: `${provider.name.display || 'Provider'} Hospital Affiliations`
                }
            };
            
            $scope.reportOptionsGroup = {
                pagination: {
                    disable: true
                },
                filterInput: {
                    disable: true
                },
                configure: {
                    disable: true
                },
                export: {
                    name: `${provider.name.display || 'Provider'} Group Affiliations`
                }
            };
            
            $scope.reportOptionsHospitalAndGroupAffiliates = {
                configure: {
                    disable: true
                },
                export: {
                    name: `${provider.name.display || 'Provider'} Affiliates`
                }
            };
            
            updateList();
            
            CommunityList.update([$scope.provider]);
            
            // ui-leaflet
            angular.extend($scope, {
                center: {
                    lat: provider.location.coordinates.lat,
                    lng: provider.location.coordinates.lng,
                    zoom: 12
                }
            });
            
            var stamenAttribution = 'Map tiles by <a href="//stamen.com" target="_blank">Stamen Design</a>, under <a href="//creativecommons.org/licenses/by/3.0" target="_blank">CC BY 3.0</a>. Data by <a href="//openstreetmap.org" target="_blank">OpenStreetMap</a>, under <a href="//creativecommons.org/licenses/by-sa/3.0" target="_blank">CC BY SA</a>';
            var openStreetMapAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
        
            var tileOptions = {
                maxZoom: 19,
                subdomains: ['a','b','c']
            };
    
            var stamen = new L.tileLayer('//stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png', {attribution: stamenAttribution}, tileOptions);
            var streets = new L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: openStreetMapAttribution}, tileOptions);
        
            var baseMaps = {
                "Grayscale": stamen,
                "OpenStreetMap": streets
            };
        
            var preferredBase = $localstorage.get('map.baseLayerPreference');   
            
            var preferredOverlay = $localstorage.get('map.overlayPreference');
        
            leafletData.getMap().then(function(map) {
                (baseMaps[preferredBase] || stamen).addTo(map);
                L.marker([provider.location.coordinates.lat, provider.location.coordinates.lng]).addTo(map)
                .bindPopup(provider.name.display)
                .openPopup();
            });
            
            $scope.$on('leafletDirectiveMap.baselayerchange', function(event, obj) {
                $localstorage.set('map.baseLayerPreference', obj.leafletEvent.name);
            });
            
            $scope.$on('leafletDirectiveMap.overlayadd', function(event, obj) {
                $localstorage.set('map.overlayPreference', obj.leafletEvent.name);
            });
            
            $scope.provider.GetServiceArea()
            .then(function(serviceAreaData) {
                if (!angular.equals(serviceAreaData.service_area, {})) {
                    
                    var serviceAreaKeys = Object.keys(serviceAreaData.service_area);
                    var actual, modeled;
    
                    var overlayMaps = {
                        'Service Area': {
                            'Actual': actual = new L.layerGroup(), 
                            'Modeled': modeled = new L.layerGroup()
                        }
                    };
    
                    var options = {
                        exclusiveGroups: ['Service Area'],
                        groupCheckboxes: true
                    };
    
                    var fitBoundsGroup = L.featureGroup();
                    
                    angular.forEach(serviceAreaKeys, function(key) {
                        var color;
                        
                        switch(key) {
                            case 'primary':
                                color = '#e6550d';
                                break;
                            case 'primary_modeled':
                                color = '#756bb1';
                                break;
                            case 'secondary':
                                color = '#fdae6b';
                                break;
                            case 'secondary_modeled':
                                color = '#bcbddc';
                                break;
                            default:
                                color = '#333';
                        }
                        
                        var polyLayer = L.geoJson(serviceAreaData.service_area[key], {
                            color: color,
                            fillColor: color,
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 0.6
                        });
                        
                        if (key.includes('modeled')) {
                            modeled.addLayer(polyLayer.bindPopup((key.includes('primary')? 'Primary' : 'Secondary') + ' Service Area - Modeled'));
                        } else {
                            actual.addLayer(polyLayer.bindPopup((key.includes('primary')? 'Primary' : 'Secondary') + ' Service Area - Actual'));
                        }
                        
                        fitBoundsGroup.addLayer(polyLayer);
                    });
                    
                    var legends = {
                        'Actual': L.control({position: 'bottomleft'}),
                        'Modeled': L.control({position: 'bottomleft'})
                    };
                    
                    legends['Actual'].onAdd = function() {
                        var div = L.DomUtil.create('div', 'info legend');
                        
                        div.innerHTML += 
                        '<div class="actual">' +              
                        '<i style="background:#e6550d"></i> Primary Service Area <br/>' +
                        '<i style="background:#fdae6b"></i> Secondary Service Area <br/>' +
                        '</div>';
                        
                        return div;
                    };
                    
                    legends['Modeled'].onAdd = function() {
                        var div = L.DomUtil.create('div', 'info legend');
                        
                        div.innerHTML += 
                        '<div class="modeled">'+
                        '<i style="background:#756bb1"></i> Primary Service Area <br/>' +
                        '<i style="background:#bcbddc"></i> Secondary Service Area <br/>' +
                        '</div>';
                        
                        return div;
                    };
    
                    leafletData.getMap().then(function(map) {
                        (overlayMaps['Service Area'][preferredOverlay] || overlayMaps['Service Area']['Actual']).addTo(map);
                        map.fitBounds(fitBoundsGroup.getBounds());
                        L.control.groupedLayers(baseMaps, overlayMaps, options).addTo(map);
                        (legends[preferredOverlay] || legends['Actual']).addTo(map);
                    });
                    
                    $scope.$on('leafletDirectiveMap.overlayadd', function(event, obj) {
                        var legendToRemove;
                        
                        if (obj.leafletEvent.name === 'Actual') {
                            legendToRemove = legends['Modeled'];
                        } else {
                            legendToRemove = legends['Actual'];
                        }
                        
                        leafletData.getMap().then(function(map) {
                            map.removeControl(legendToRemove);
                            legends[obj.leafletEvent.name].addTo(map);
                        });
                    });
                    
                } else {
                    
                    leafletData.getMap().then(function(map) {
                        L.control.layers(baseMaps).addTo(map);
                    });
                    
                }
            }, function(err) {
                console.log('GetServiceArea error');
            });
            
        };
        
        $scope.$on('communitiesUpdated', function(broadcast, updatedCommunity) {
            updateList();
        });
        
        function getformattedCommunities() {
            var communities = pic(provider.npi, true).map(function(community) {
                $scope.originalCommunities[community.id] = community;
                return {
                    'name': community.name,
                    'abbr': community.abbr,
                    'id': community.id,
                    'owner': community.insert_user,
                    'is_public': community.is_public,
                    'watched': community.watched,
                    'owned': community.owned
                };
            });
            return communities;
        }
        
        function getMyCommunities() {
            return communities.filter(function(community) {
                return community.owned;
            });
        }
        
        function getSharedCommunities() {
            return communities.filter(function(community) {
                return community.is_public;
            });
        }
        
        function updateList() {
            communities = getformattedCommunities();
            $scope.myCommunities = getMyCommunities();
            $scope.sharedCommunities = getSharedCommunities();
        }
        
        $ctrl.deleteMember = function(community) {
            community.RemoveMember(provider)
            .then(function() {
                updateList();
            });
        };
        
        $ctrl.share = function(community, index) {
            $scope.index = index;
            $scope.updateSharing = true;
            community.Update({is_public: !community.is_public})
            .then(function() {
                updateList();
                $scope.updateSharing = false;
            });
        };
        
        $ctrl.watch = function(community, index) {
            $scope.index = index;
            $scope.updateWatching = true;
            community.Update({watched: !community.watched})
            .then(function() {
                updateList();
                $scope.updateWatching = false;
            });
        };
        
        $ctrl.copy = function(community, index) {
            $scope.index = index;
            $scope.updateCopying = true;
            community.Copy()
            .then(function() {
                updateList();
                $scope.updateCopying = false;
            });
        };
        
        $ctrl.updateCommunityList = function(affiliationsToUse) {
            CommunityList.update([...new Set(affiliationsToUse.map(afl => afl.npi).filter(afl => afl))]);
        };
        
        var resizeDisposal = template.contentResize(function(){
            $timeout(function() {
                leafletData.getMap().then(function(map) {
                    map.invalidateSize();
                });
            }, 300);
        });
        
        $scope.$on('$destroy', resizeDisposal);
    }
];