import * as d3 from 'd3';
import tourConfig from './tourConfig';

export default ['$scope', '$http', '$state', '$q', '$window', 'api', 'config', 'notify', '$timeout', 'tour', '$templateCache',
    function($scope, $http, $state, $q, $window, api, config, notify, $timeout, tour, $templateCache) {
        var $ctrl = this;
        
        $scope.axis = 'shared';
        
        $scope.mapping = {
            shared: {
                units: 'Shared Visits',
                sortAxis: 'totalShared'
            },
            same: {
                units: 'Same Day Visits',
                sortAxis: 'totalSame'
            },
            unique: {
                units: 'Unique Patients',
                sortAxis: 'totalUnique'
            }
        };
        
        var allStringVal = '-- All Filters Below --';
        
        function bindSankeyMouseClick($scope){
            
            $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
                if (datum instanceof api.Provider) {
                    var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                    if (newTabUrl) {// for standalone
                        $window.open(newTabUrl, '_blank');
                    }
                }
            });
            
        }
        
        bindSankeyMouseClick($scope);
        
        function build(index, nodes, prev){
            
            if (index > $ctrl.filterControls.length - 1) {
                console.log('invalid index', index);
                return;
            }
            
            if (!nodes) {
                nodes = getActiveFromColumn(index);
            }
            
            var list = getActiveFromColumn(index)
                .map(function(node){
                    switch(index) {
                        case 0:
                            node.side = 'left';
                            break;
                        case 1:
                            node.side = 'middle';
                            break;
                        case 2:
                            node.side = 'right';
                            break;
                        default:
                            node.side = 'extended';
                            break;
                    }
                    return node;
                })
                .filter(function(node){
                    return nodes.indexOf(node) > -1;
                })
                .sort(function(a,b){
                    return b[$ctrl.options.node.sortAxis] - a[$ctrl.options.node.sortAxis];
                })
                .slice(0, $ctrl.options.limit);
                
            var relations = list.reduce(function(acc, node){
                acc.push.apply(acc, node.outBound);
                return acc;
            },[]);
            
            if (relations.length > 0) {
                var outBound = build(index + 1, relations.reduce(function(acc, relation){
                    acc.push(relation.target);
                    return acc;
                }, []), list);
                var inBound = outBound
                    .reduce(function(acc, relation){
                        if (acc.indexOf(relation.source) === -1) {
                            acc.push(relation.source);
                        }
                        return acc;
                    },[])
                    .filter(function(node){
                        return list.indexOf(node) > -1;
                    })
                    .reduce(function(acc, node){
                        acc.push.apply(acc, node.inBound);
                        return acc;
                    },[])
                    .filter(function(relation){
                        return !prev || prev.indexOf(relation.source) > -1;
                    });
                    
                return inBound.concat(outBound);
                
            } else {
                return list.reduce(function(acc, node){
                    acc.push.apply(acc, node.inBound);
                    return acc;
                },[])
                .filter(function(relation){
                    return !prev || prev.indexOf(relation.source) > -1;
                });
            }
            
        }
        
        function getActiveFromColumn(columnIndex){
            
            var nodes = [];
            
            if ($ctrl.filterControls[columnIndex][allStringVal].active === true) {// if 'All' active we take its nodes, sort them, and return the top limit (default 10 or less)
                
                return $ctrl.filterControls[columnIndex][allStringVal].nodes;
                
            } else {// if 'All' is not active find the nodes present in each filter
            
                for (var specClass in $ctrl.filterControls[columnIndex]) {// each filter in the bin object
                    if ($ctrl.filterControls[columnIndex][specClass].name !== allStringVal && $ctrl.filterControls[columnIndex][specClass].active === true) {// if not the 'all' filter and active add its nodes to the current column
                        nodes = nodes.concat($ctrl.filterControls[columnIndex][specClass].nodes);
                    }
                }
                
                return nodes;
            }
            
        }
        
        function buildAndSendRelations() {
            var list;
            if (
                $ctrl.rawNodesBins[0].length === 0 ||
                $ctrl.rawNodesBins[1].length === 0 ||
                $ctrl.rawNodesBins[2].length === 0
            ) {
                $scope.hasDataFetchError = true;
                notify.alert({
                    title: 'DATA',
                    text: 'Insuficient provider data to render chart.',
                    delay: 30000
                });
            } else {
                list = build(0);
            }
            
            if ($ctrl.reportSankey) {//if in main app, put data on parent sankey
                $ctrl.reportSankey.data(list);
            } else {// otherwise, if in standalone, put data on specialty flow
                $scope.options = $ctrl.options;
                $scope.data = list;
            }
        }
        
        $ctrl.stateColumn = function(number){
            var ret;
            switch (number) {
                case 0:
                    ret = 'Left';
                    break;
                case 1:
                    ret = 'Middle';
                    break;
                case 2:
                    ret = 'Right';
                    break;
                default:
                    ret = 'Additional Targets';
                    break;
            }
            return ret;
        };
        
        $ctrl.setFilterColumnClass = function(index, isButton){
            
            if (isButton) {
                if (index === undefined) {// the default filter reset button
                    if ($ctrl.npis[0].entitytype == 2) {// an org
                        return false;
                    } else if ($ctrl.pcpTaxonomyCodes.indexOf($ctrl.npis[0].taxonomy.code) > -1) {// a PCP
                        return false;
                    } else {// a Specialist
                        return false;
                    }
                    
                } else if (index === 0) {// left nodes
                    if ($ctrl.npis[0].entitytype == 2) {// an Org
                        return false;
                    } else if ($ctrl.pcpTaxonomyCodes.indexOf($ctrl.npis[0].taxonomy.code) === -1) {// a Specialist
                        return false;
                    } else {// a PCP
                        return true;
                    }
                } else if (index === 1) {// middle nodes
                    if ($ctrl.npis[0].entitytype == 2) {// an Org
                        return false;
                    } else if ($ctrl.pcpTaxonomyCodes.indexOf($ctrl.npis[0].taxonomy.code) > -1) {// a PCP 
                        return false;
                    } else {// a Specialist
                        return true;
                    }
                } else {// right nodes column and all other columns
                    return true;
                }
            } else {
                if (index === 2) {// hide right (GAC) nodes column
                    return 'hidden';
                } else {
                    return 'col-md-6';
                }
            }
            
        };
        
        $ctrl.numberOfFilters = function(columnHash){//returns number of active filters
            return Object.keys(columnHash).filter(function(key){
                return columnHash[key].active;
            }).length;
        };
        
        function areNonAllControlsInactive(controlObj){// returns true if there aren't any active filters other than All
            return Object.keys(controlObj).filter(function(key) {
                return key !== allStringVal && controlObj[key].active === true;
            }).length === 0;
        }
        
        $ctrl.toggleFilters = function(classSpec, controlIndex){
            if (classSpec === allStringVal && $ctrl.filterControls[controlIndex][allStringVal].active === true) {
                for (var item in $ctrl.filterControls[controlIndex]) {
                    if (item !== allStringVal && $ctrl.filterControls[controlIndex][item].active === true) {
                        $ctrl.filterControls[controlIndex][item].active = false;
                    }
                }
            } else if ($ctrl.filterControls[controlIndex][allStringVal].active === true && $ctrl.filterControls[controlIndex][classSpec].active === true) {
                $ctrl.filterControls[controlIndex][allStringVal].active = false;
            } else if ($ctrl.filterControls[controlIndex][allStringVal].active === false && areNonAllControlsInactive($ctrl.filterControls[controlIndex])) {
                $ctrl.filterControls[controlIndex][allStringVal].active = true;
            }
            
            buildAndSendRelations();
        };
        
        $ctrl.toggleAxis = function(property) {
            
            $ctrl.options.link = {
                value: function(d) {
                    return d.values[property];
                }
            };
            
            //preserves $ctrl.options.toolTip.template when used in standalone
            angular.merge($ctrl.options.toolTip, {
                units: $scope.mapping[property].units,
                value: function(d) {
                    return d.values[property];
                } 
            });
            
            $ctrl.options.node.sortAxis = $scope.mapping[property].sortAxis;
            
            if ($ctrl.reportSankey) {//falsy if used within standalone
                $ctrl.reportSankey.options($ctrl.options);// set options on the parent component
            }
            
            buildAndSendRelations();
        };
        
        $ctrl.resetFilters = function() {
            
            $ctrl.filterControls.forEach(function(column){
                for (var columnItemKey in column) {
                    if (column[columnItemKey].active === undefined) {// handle angular's hashkeys
                        return;
                    } else {
                        if (columnItemKey === allStringVal) {
                            column[columnItemKey].active = true;
                        } else {
                            column[columnItemKey].active = false;
                        }
                    }
                }
            });
            
            $scope.axis = 'shared';
            
            $ctrl.toggleAxis('shared');

        };
        
        $ctrl.getBgColor = function(specClass){
            if (specClass !== allStringVal) {
                return { 'background-color': $ctrl.options.color.value(specClass) };
            } else {
                return { 'background-color': 'none'};
            }
            
        };
        
        $ctrl.showFilterColumn = function(index){
            if (index === 0) {// left nodes
                if ($ctrl.npis[0].entitytype == 2) {// an Org
                    return true;
                } else if ($ctrl.pcpTaxonomyCodes.indexOf($ctrl.npis[0].taxonomy.code) === -1) {// a Specialist
                    return true;
                } else {// a PCP
                    return false;
                }
            } else if (index === 1) {// middle nodes
                if ($ctrl.npis[0].entitytype == 2) {// an Org
                    return true;
                } else if ($ctrl.pcpTaxonomyCodes.indexOf($ctrl.npis[0].taxonomy.code) > -1) {// a PCP 
                    return true;
                } else {// a Specialist
                    return false;
                }
            } else {// right nodes column and all other columns, TODO: change when we add additional columns
                return false;
            }
        };
        
        $ctrl.hasName = function(){
            return function(value){
                return value.name !== undefined;
            };
        };
        
        $ctrl.$onInit = function(){
            
            $ctrl.options = {
                controlled: true,
                colorLegend: true,
                limit: 10,
                color: {
                    scale: d3.scaleOrdinal(d3.schemeCategory20),
                    value: function(d) {
                        return this.scale(d);
                    }
                },
                node: {
                    colorValue: function(d){
                        return d.taxonomy.toString();
                    },
                    label: function(d){
                        if (d.entitytype == 2) {
                            return d.name.display+' ('+d.npi+')';
                        } else {
                            return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                        }
                    },
                    sortAxis: 'totalShared'
                },
                link: {
                    value: function(d) {
                        return d.values.shared;
                    }
                },
                toolTip: {
                    units: 'Shared Visits',
                    value: function(d) {
                        return d.values.shared;
                    } 
                }
            };
            
            if (!$ctrl.reportSankey) {// for standalone
                $ctrl.options.toolTip.template = $templateCache.get('ng-template/sankeySpecialtyFlowToolTip.html');
            }
            
            tour.setScript(tourConfig($timeout), $scope);
            
            if ($ctrl.reportSankey) {
                 $ctrl.reportSankey.options($ctrl.options);// set options on the parent component
            }
            
            $ctrl.pcpTaxonomyCodes = config.pcpTaxonomyCodes();// set array of strings that are taxonomy codes
            
            $ctrl.seedProvider = $ctrl.npis[0];// TODO: commmunity will need its own controller
            var specialtyFlowEndpoint = '/api/npi/'+ $ctrl.seedProvider.npi +'/specialty_flow/';
            
            $ctrl.seedProvider.Report('specialtyflow')
                .then(function(response){
                    
                    $ctrl.allRelations = response.relations;// set up array of all relations before any filters and npi resolution
                    var responseNpiPromises = Object.keys(response.npis)
                        .map(function(key){
                            return api.GetProvider(key);
                        });
                    
                    return $q.all(responseNpiPromises);// resolve npis with with the npi service
                    
                })
                .then(function(npis){
                    npis = npis.map(function(provider){// set empty inbound and outbound relations arrays on resolved npis array
                        var ret = {
                            inBound: [],
                            outBound: [],
                        };
                        ret.__proto__ = provider;
                        return ret;
                    });
                    var npisHash = npis.reduce(function(hash, provider){// set up hash table for easy lookup
                        hash[provider.npi] = provider;
                        return hash;
                    },{});
                    
                    $ctrl.allRelations = $ctrl.allRelations
                        .map(function(relationDatum){// change the allRelations array replacing with relation instance using the resolved npis
                            var relation = new api.Relation({
                                values: relationDatum.values,
                                source: npisHash[relationDatum.source],
                                target: npisHash[relationDatum.target]
                            });
                            
                            relation.source.outBound.push(relation);// add the current relation to the source npi as an outbound relation
                            relation.target.inBound.push(relation);// add the current relation to the target npi as an inbound relation
                            
                            return relation;
                            
                        })
                        .filter(function(relation){// current fix to remove specialist to specialist relations
                            if (
                                relation.source.entitytype == 1 && relation.target.entitytype == 1
                                && $ctrl.pcpTaxonomyCodes.indexOf(relation.source.taxonomy.code) === -1
                                && $ctrl.pcpTaxonomyCodes.indexOf(relation.target.taxonomy.code) === -1
                            ) { 
                                return false;
                            } else {
                                return true;
                            }
                        });
                    
                    npis = npis.filter(function(npi){// remove npis not found in relations (those lost after after specialty -> specialty relations filter)
                        var timesInRelations = $ctrl.allRelations.filter(function(relation){
                            return (relation.source == npi || relation.target == npi);
                        });
                        if (timesInRelations.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    
                    $ctrl.rawNodesBins = npis.reduce(function(acc, npi){// take all npis and put them into one of three bins for use in filter inputs 
                        
                        if (npi.entitytype == 1) {
                            if ($ctrl.pcpTaxonomyCodes.indexOf(npi.taxonomy.code) > -1) {// if a PCP put in bin [0]
                                acc[0].push(npi);
                                npi.column = 0;
                                return acc;
                            } else {// if a Specialist (e.g. type 1 and not a PCP) put in bin [1]
                                acc[1].push(npi);
                                npi.column = 1;
                                return acc;
                            }
                        } else {// otherwise must be type 2 (an org) and put in bin [2]
                            acc[2].push(npi);
                            npi.column = 2; 
                            return acc;
                        }
                    },[[],[],[]]);
                    
                    $ctrl.rawNodesBins.forEach(function(bin){// add totalShared property to each node descending and sort each bin on it
                        
                        bin.forEach(function(node){// add totalshared property
                            node.totalShared = 0;
                            node.totalSame = 0;
                            node.totalUnique = 0;
                            [node.inBound, node.outBound].forEach(function(relationsArr){
                                relationsArr.forEach(function(relation){
                                    node.totalShared += relation.values.shared;
                                    node.totalSame += relation.values.same;
                                    node.totalUnique += relation.values.unique;
                                });
                            });
                        });
                        
                        bin.sort(function(a, b){// sort bin descending on totalShared
                            return b.totalShared - a.totalShared;
                        });
                        
                    });
                    
                    $ctrl.filterControls = $ctrl.rawNodesBins.map(function(column, columnIndex){// map over the three bins and set hash table in each for use in filtering
                        
                        var columnValueAll = {// each hash table will have an 'All' value that with include all nodes of its type
                            nodes: [],
                            active: true,// all is active on route load
                            name: allStringVal
                        };
                        
                        var hash = column.reduce(function(acc, columnItem){
                            
                            var name = columnItem.taxonomy.toString();
                            
                            if (!acc[name]) {//set filter entry if it doesn't yet exist by name
                                acc[name] = {
                                    nodes: [],
                                    active: false,// each filter inactive by default since all is active
                                    name: name
                                };
                            }
                            columnValueAll.nodes.push(columnItem);//add each node to the all filter
                            acc[name].nodes.push(columnItem);//and also its appropriate group based on name
                            return acc;
                            
                        },{});
                        hash[allStringVal] = columnValueAll;// add 'All' to each hash
                        return hash;
                    });
                    
                    buildAndSendRelations();//all data ready to process based on active filters and send to sankey parent controller
                    
                }).catch(function(error){
                    console.log('Error fetching Speciality Flow Graph data');
                    $scope.hasDataFetchError = true;
                    notify.error({
                        title: 'ERROR',
                        text: 'Error fetching Speciality Flow Graph data.',
                        delay: 30000
                    });
                    $ctrl.reportSankey.data([]);//needed to get sankey loading spinner to stop
                });
            
        };
    }
];