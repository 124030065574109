import React, {useEffect, useState} from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import quicksight from '../../services/quicksightModule';
import { useLogging } from '../../services/servicesContext';

export function DashboardComponent(props) {
    const [dashboards, setDashboards] = useState(null)
    const [loading, setLoading] = useState(false)
    const logging = useLogging();
    const quickSight = quicksight();

    function getDashboards() {
        quickSight.getDashboardList()
        .then(list => {
            setDashboards(quickSight.filterDashboardsList(list, {hideHidden: true}));
            setLoading(false);
        }, err => {
            console.error('error getting dashboard list');
        })
    }

    useEffect(() => {
        logging.routeLoad({
          pathname: location.pathname,
          npis: [],
          statename: 'root.app.dbs.home'
        });
        setLoading(true);
        getDashboards();
    }, []);

    return (
    <div>
        <h2>{props?.name}</h2>
        <ul style={{ paddingLeft: 0}}>
          { dashboards
            ? Object.keys(dashboards).map(dashboardID => {
              let dashboard = dashboards[dashboardID]
              return (
                <div className="panel panel-success">
                  <div className="panel-heading">
                      <h3 className="panel-title">
                        <Link style={{cursor : 'pointer'}} to={dashboardID}>
                        {dashboard.name} <i className="fa fa-link" aria-hidden="true"></i>
                        </Link>
                      </h3>
                  </div>
                  <div className="panel-body">{dashboard.description}</div>
                </div>
              );
            })
            : null
          }
        </ul>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
        </div>
    )
}
