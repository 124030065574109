/* global L  */
import DOMPurify from 'dompurify';
import jQuery from 'jquery';
import * as d3 from 'd3';

import 'leaflet-v171/dist/leaflet';// create global L as side effect
import 'leaflet-v171/dist/leaflet.css';
import 'leaflet-layer-tree-plugin/src/leaflet-layer-tree-control';// extend global L as side effect
import 'leaflet-layer-tree-plugin/src/leaflet-layer-tree-control-wfs-zoom';
import 'leaflet-layer-tree-plugin/src/leaflet-layer-tree-control.css';
import './spatial-sankey';

import 'font-awesome/css/font-awesome.css';

(function($){
	
	$.fn.geoflow = function(options){
		var providername = options.providername,
			NPI = options.NPI,
			entitytypecode = options.entitytypecode,
			color = d3.scaleOrdinal(d3.schemeCategory20),
			geojsonData = options.data,
			filterOpen = options.filterOpen || false,
			geoflowComponentElem = $(options.geoFlowComponentElementName)[0];//used later for calculating map offset for tooltip and appending tooltip to DOM

		var LEAKAGE_LINES = '#d62728',
			LOYAL_LINES = '#005C85',
			COMPETITOR_NODES = '#756bb1',
			SELECTED_HOSPITAL_NODE = '#2ca02c',
			LEAKAGE_THRESHOLD = [0, 12.5, 25.0, 37.5, 50.0, 62.5, 75.0, 87.5],
			LINK_OPACITY = 0.6,
			NODE_OPACITY = 0.7,
			MAP_ZOOM = 8,
			RECENT_LIST_LENGTH = 9,
			SVG_CIRCLE_HEIGHT = 16,
			SVG_CIRCLE_WIDTH = 20,
			LEGEND_CX = 8,
			LEGEND_CY = 8,
			LEGEND_RADIUS = 8,
			SVG_LINE_HEIGHT = 5,
			SVG_LINE_WIDTH = 20,
			LEGEND_STROKE_WIDTH = 5,
			LEGEND_X1 = 0,
			LEGEND_Y1 = 0,
			LEGEND_X2 = 16,
			LEGEND_Y2 = 0,
			RADIUS = 1000,
			INCREASE_BY = 30,
			STROKE = '#5e5e5e',
			STROKE_WIDTH = '1.5',
			ZOOM_MIN = 4;

		var getCheckedInputs = function(opt){
			// get all checked NPIs
			var checked = [];
			
			$('.recentInputList').each(function(i, elem){
				if (opt == 'checked'){
					if (elem.checked){
						checked.push(elem.id);
					}
				} else if (opt == 'all'){
					checked.push(elem.id);
				}
			});
			return checked;
		};

		$(options.geoFlowComponentElementName).prepend('<div id="geoflow-tooltip" class="hidden">'
					+ '<p><span id="heading"></span></p>'
					+ '<p><span id="visits"></span></p>'
					+ '<p><span id="otherinfo"></span></p>'
					+ '</div>');
		
		//allows for rerender of map on new data without refreshing the page		
		$(this).empty();
		var container = $('<div class="geoflowMap" style="width:100%;height:100%"></div>').appendTo(this);
		
		var openStreetMapUrl = '//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		openStreetMapAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
		openStreetMapdomains = ['a','b','c'];
			
		var openStreetMapLayer = new L.TileLayer(openStreetMapUrl, {subdomains: openStreetMapdomains, attribution: openStreetMapAttribution});
		
		var map = new L.Map(container.get(0), { minZoom: ZOOM_MIN });
		map.setView(new L.LatLng(35.9584672, -86.8201798), options.zoom); // ph offices coordinates
		map.addLayer(openStreetMapLayer );
		
		// Initialize the SVG layer
		L.svg({clickable:true}).addTo(map);// _initPathRoot() removed from 1.x.x
		map.doubleClickZoom.disable(); 

		if (options.mapSizeCallback){
			options.mapSizeCallback(map);
		}

		// Setup svg element to work with
		var svg = d3.select(this.get(0)).select("svg").attr("pointer-events", "auto"),//event handlers will not work without pointer events set to something other than none
			linklayer = svg.append("g"),
			nodelayer = svg.append("g"),
			headerText, bodyText, 
			showingAll = false,
			hoveredOverHospitalNode = false,
			linkType = 'target',
			ignoreHover = false,
			showAll = options.showAll || false;

		var formatNumber = d3.format(",d");

		if (options.legend){

			var linksLegend = L.control({position: 'bottomleft'});
			linksLegend.onAdd = function (map) {
				var div = L.DomUtil.create('div', 'info legend');
				div.innerHTML = '<div id="linksLegend">'
							  + '<svg height="'+SVG_LINE_HEIGHT+'" width="'+SVG_LINE_WIDTH+'">'
							  +    '<line x1="'+LEGEND_X1+'" y1="'+LEGEND_Y1+'" x2="'+LEGEND_X2+'" y2="'+LEGEND_Y2+'" style="stroke:'+LEAKAGE_LINES+';stroke-width:'+LEGEND_STROKE_WIDTH+';opacity:'+LINK_OPACITY+';"/>'
							  +    '</svg> Visits to Other Providers'
							  + '<br><svg height="'+SVG_LINE_HEIGHT+'" width="'+SVG_LINE_WIDTH+'">'
							  +    '<line x1="'+LEGEND_X1+'" y1="'+LEGEND_Y1+'" x2="'+LEGEND_X2+'" y2="'+LEGEND_Y2+'" style="stroke:'+LOYAL_LINES+';stroke-width:'+LEGEND_STROKE_WIDTH+';opacity:'+LINK_OPACITY+';"/>'
							  +    '</svg> Visits to Selected Provider'
				              + '<br><svg height="'+SVG_CIRCLE_HEIGHT+'" width="'+SVG_CIRCLE_WIDTH+'">'
							  +    '<circle cx="'+LEGEND_CX+'" cy="'+LEGEND_CY+'" r="'+(LEGEND_RADIUS-1)+'" opacity="'+NODE_OPACITY+'" style="fill:'+COMPETITOR_NODES+';opacity:'+NODE_OPACITY+';stroke:'+STROKE+';stroke-width:'+STROKE_WIDTH+';">'
							  +    '</circle>'
							  + '</svg> Other Providers'
							  + '<br><svg height="'+SVG_CIRCLE_HEIGHT+'" width="'+SVG_CIRCLE_WIDTH+'">'
							  +    '<circle cx="'+LEGEND_CX+'" cy="'+LEGEND_CY+'" r="'+(LEGEND_RADIUS-1)+'" opacity="'+NODE_OPACITY+'" style="fill:'+SELECTED_HOSPITAL_NODE+';opacity:'+NODE_OPACITY+';stroke:'+STROKE+';stroke-width:'+STROKE_WIDTH+';">'
							  +    '</circle>'
							  + '</svg> Selected Provider'
							  + '<br><svg height="'+SVG_CIRCLE_HEIGHT+'" width="'+SVG_CIRCLE_WIDTH+'">'
							  +    '<circle cx="'+LEGEND_CX+'" cy="'+LEGEND_CY+'" r="'+(LEGEND_RADIUS-1)+'" opacity="'+NODE_OPACITY+'" stroke="#2b3038" stroke-width="'+STROKE_WIDTH+'" style="fill:none;opacity:'+NODE_OPACITY+';">'
							  +    '</circle>'
							  + '</svg> In a Community <span class="fa fa-users" aria-hidden="true"></span>'
							  + '</div>'
				return div;
			};
			linksLegend.addTo(map);

			var legend = L.control({position: 'bottomleft'});
			legend.onAdd = function (map) {
			    var div = L.DomUtil.create('div', 'info legend');
			    // loop through our density intervals and generate a label with a colored square for each interval
			    for (var i = 0; i < LEAKAGE_THRESHOLD.length; i++) {
			        div.innerHTML +=
			        	(i == 0 ? "<p class='center-align'>Share to Competitors %</p>" : '')
			            + '<svg height="'+SVG_CIRCLE_HEIGHT+'" width="'+SVG_CIRCLE_WIDTH+'">'
						+    '<circle cx="'+LEGEND_CX+'" cy="'+LEGEND_CY+'" r="'+(LEGEND_RADIUS-1)+'" opacity="'+NODE_OPACITY+'" style="fill:'+d3.spatialsankey().getColor(LEAKAGE_THRESHOLD[i])+';opacity:'+NODE_OPACITY+';stroke:'+STROKE+';stroke-width:'+STROKE_WIDTH+';">'
						+    '</circle>'
						+ '</svg>'
						+ (LEAKAGE_THRESHOLD[i]+(i==0 ? 0 : 0)) + (LEAKAGE_THRESHOLD[i + 1] ? ' &ndash; ' + LEAKAGE_THRESHOLD[i + 1] + '<br>' : '+');
				}
				return div;
			};
			legend.addTo(map);
		}

		if (options.recentLegend){

			var ignoreHoverLegend = L.control({position: 'topleft'});
			ignoreHoverLegend.onAdd = function (map) {
				var div = L.DomUtil.create('div', 'info legend');
				div.innerHTML =	'<div id="ignoreHoverLegend" class="checkbox">'
							  + '<label>'
							  +    '<input type="checkbox" id="ignoreHover" value="polygon">Ignore Hover'
							  + '</label><br>'
							  + '</div>';
				return div;
			};
			ignoreHoverLegend.addTo(map);

			$('#ignoreHover').on('click', function(){
				if (!ignoreHover){
					ignoreHover = true;
				} else {
					ignoreHover = false;
				}
			});

			var highlightAll = L.control({position: 'topleft'});
			highlightAll.onAdd = function (map) {
				var div = L.DomUtil.create('div', 'info legend');
				var checked = (showAll ? 'checked' : '');
				div.innerHTML =	'<div id="highlightAll" class="checkbox">'
							  + '<label>'
							  +    '<input type="checkbox" id="highlightAll" '+checked+' value="polygon">Highlight All'
							  + '</label><br>'
							  + '</div>';
				return div;
			};
			highlightAll.addTo(map);

			var recentLegend = L.control({position: 'topright'});
			recentLegend.onAdd = function (map) {
				var div = L.DomUtil.create('div', 'info legend');
				div.innerHTML = '<p class="center-align">Highlight Links</p>'
							  +	'<div id="recentLegend" class="checkbox">'
							  + '<label>'
							  +    '<input class="recentInputList" type="checkbox" id="' + NPI + '" value="polygon">' + providername
							  + '</label><br>'
							  + '</div>';
				return div;
			};

			recentLegend.addTo(map);
		}

		var handleData = function(geojson){ // .get data\
			// bind click event to selected organization NPI in Recent Links on page load
			$('#'+NPI).on('click', function(){
				hideSpecificLinks(NPI);
				if ($(this)[0].checked){
					specificLinks(NPI);
				}
			});
			
			map.panTo(new L.LatLng(geojson.source.lat, geojson.source.lng),{animate:false});// won't work without animate boolean as false
			map.zoomIn(MAP_ZOOM);

			var nodes = geojson.nodes;
			var links = geojson.links;
			var features = nodes.features;

			var specialtyFilterList = Object.keys(geojson.nodes.features).map(function(key){
				if(geojson.nodes.features[key].entitytype == 1) {
					return geojson.nodes.features[key].classification;
				}
			}).filter(function(currentString, currentIndex, unfilteredArray) {
				if (currentString == undefined) {
					return false;
				}
				return currentIndex == unfilteredArray.indexOf(currentString);
			});

			var tree = Object.keys(geojson.nodes.features).map(function(key) {
				return {
					code: 'npi_'+geojson.nodes.features[key].npi,
					name: geojson.nodes.features[key].name,
					taxonomytype: geojson.nodes.features[key].taxonomytype,
					classification: geojson.nodes.features[key].classification,
					specialization: geojson.nodes.features[key].specialization || '(Non-Specialist)',
					npi: geojson.nodes.features[key].npi,
					active: true,
					selectedByDefault: true,
					openByDefault: false,
					selectType: 'MULTIPLE',
					params: {},
					_node: geojson.nodes.features[key],
					childLayers: []
				};
			}).reduce(function(acc, currVal){
				if (!acc.types[currVal.taxonomytype]) {
					acc.types[currVal.taxonomytype] = {
						classes: {}
					};
				}
				if (!acc.types[currVal.taxonomytype].classes[currVal.classification]) {
					acc.types[currVal.taxonomytype].classes[currVal.classification] = {
						specs: {}
					};
				}
				if (!acc.types[currVal.taxonomytype].classes[currVal.classification].specs[currVal.specialization]) {
					acc.types[currVal.taxonomytype].classes[currVal.classification].specs[currVal.specialization] = [
						currVal
					];
				} else {
					acc.types[currVal.taxonomytype].classes[currVal.classification].specs[currVal.specialization].push(currVal);
				}
				return acc;
			}, {
				types: {}
			});

			tree = Object.keys(tree.types).sort().reduce(function(ret, taxonomytype) {
				var taxonomyNode = {
					code: 'taxonomies',
					name: taxonomytype,
					active: true,
					selectedByDefault: true,
					openByDefault: false,
					selectType: 'MULTIPLE',
					serviceType: null,
					params: {},
					childLayers: []
				};
				Object.keys(tree.types[taxonomytype].classes).sort().reduce(function(ret, classification){
					var classNode = {
						code: 'classifications',
						name: classification,
						active: true,
						selectedByDefault: true,
						openByDefault: false,
						selectType: 'MULTIPLE',
						serviceType: null,
						params: {},
						childLayers: []
					};
					Object.keys(tree.types[taxonomytype].classes[classification].specs).sort().reduce(function(ret, specialization){
						var specialtyNode = {
							code: 'specializations',
							name: specialization,
							active: true,
							selectedByDefault: true,
							openByDefault: false,
							selectType: 'MULTIPLE',
							serviceType: null,
							params: {},
							childLayers: tree.types[taxonomytype].classes[classification].specs[specialization].sort()
						};
						classNode.childLayers.push(specialtyNode);
						return undefined;
					}, undefined);
					taxonomyNode.childLayers.push(classNode);
					return undefined;
				}, undefined);
				ret.childLayers.push(taxonomyNode);
				return ret;
			}, {
				code: "root",
				name: "All Providers",
				active: true,
				selectedByDefault: true,
				openByDefault: true,
				selectType: 'MULTIPLE',
				serviceType: null,
				params: {},
				childLayers: []
			});

			new L.Control.LayerTreeControl({
				position: 'bottomright',
				layerTree: tree,
				selectedByDefault: true,
				openByDefault: filterOpen,
				featureBuilders: {
					WFS: {
						zoom: L.Control.LayerTreeControl.WFSZoomFeature
					}
				}
			}).addTo(map);

			$('.leaflet-layer-tree-control-leaf-title').on('click', function(){
				showFilteredNodes();
			});

			var isEmpty = function(obj) { 
				for (var x in obj) { return false; }
				return true;
			};

			var newLatLng = function(olat, olng, r, degrees){
				var lat1 = olat * Math.PI/180.0;
				var lon1 = olng * Math.PI/180.0;
				var d = (r/6371)/1000; //radius of the earth in km, accounts for curvature
				var lon, loc;
				var tc = degrees * Math.PI/180.0; // convert to radians to use trig functions
				var lat = Math.asin(Math.sin(lat1)*Math.cos(d)+Math.cos(lat1)*Math.sin(d)*Math.cos(tc));
				lat = 180.0 * lat / Math.PI; 
				if (Math.cos(lat1) == 0){
					lon = olng;
				} else {
					lon = ((lon1 - Math.asin(Math.sin(tc) * Math.sin(d)/Math.cos(lat1)) + Math.PI) % (2 * Math.PI)) - Math.PI;
				}
				lon = 180.0 * lon / Math.PI;
				return [lat, lon];
			};

			var featureKeys = Object.keys(features);
			var geocontainer = featureKeys.reduce(function(output, npi, index){
				var lat = features[npi].geometry.coordinates.lat;
				var lng = features[npi].geometry.coordinates.lng;
				var key = lat + '|' + lng;
				if (!(key in output)){
					output[key] = {
						npis: [npi],
						coords: {
							lat: lat,
							lng: lng
						}
					};
				} else output[key].npis.push(npi);
				return output;
			}, {});

			var geocontainerKeys = Object.keys(geocontainer);
			for (var z in geocontainerKeys){
				var latLonKey = geocontainer[geocontainerKeys[z]];
				var npilist = latLonKey.npis;
				var npilist = npilist.sort(function(a,b){
					var entitytypea = features[a].type,
						entitytypeb = features[b].type;
					if (entitytypea == 'source' && entitytypeb == 'source'){
						return features[a].charges - features[b].charges;
					}
				});

				var radius = RADIUS;
				var memberCount;
				var start = memberCount = 0;
				var radiusObj = {};
				for (var i=1;i<=npilist.length;i+=1){
					var stop = start + INCREASE_BY*i;
					radiusObj[radius] = npilist.slice(start, stop);
					memberCount += radiusObj[radius].length;
					
					if (memberCount >= npilist.length) break;
					start += INCREASE_BY*i;
					radius += RADIUS; // + start;  // to decrease distance between nodes as circles get more populous
				};

				if (!(isEmpty(radiusObj))){
					for (var r in radiusObj){
						var degrees = 0;
						for (var n in radiusObj[r]){
							var npi = radiusObj[r][n];
							var lat = features[npi].geometry.coordinates.lat,
								lng = features[npi].geometry.coordinates.lng;
							var newCoords = newLatLng(lat, lng, r, degrees);
							var entitytype = features[npi].type;
							if (entitytype == 'source' || (entitytype == 'comp' && entitytypecode == '1')){
								features[npi].geometry.coordinates.lat = newCoords[0];
								features[npi].geometry.coordinates.lng = newCoords[1];
								degrees += 360/radiusObj[r].length;
							} else {
								features[npi].geometry.coordinates.lat = lat;
								features[npi].geometry.coordinates.lng = lng;
							}
						}
					}
				}
			}	

			var spatialsankey = d3.spatialsankey()
				.lmap(map)
				.nodes(nodes.features)
				.links(links);

			var nodelinks = spatialsankey.links();

			// Get link data for this node
			var nodelinks = spatialsankey.links().filter(function(link){
				return true;//showingAll ? link.source : link.source == nodes.features[d].npi || link.target == nodes.features[d].npi;
			});

			var showBeziers = function(){
				// Add data to link layer
				var beziers = linklayer.selectAll("path").data(nodelinks);
				var link = DOMPurify.sanitize(spatialsankey.link(options));

				// Draw new links
				beziers.enter()
					.append("path")
					.attr("d", link)
					.attr('class', 'bezier')
					.attr('id', function(d){return d.id})
					.style("stroke-width",  DOMPurify.sanitize(spatialsankey.link(options).width()))
					.style("stroke", function(a){
						if (!showingAll){
							return a.source == NPI || a.target == NPI ? LOYAL_LINES : LEAKAGE_LINES;
						}
					})
					.on("mousemove", mouseShowTooltip)
					.on("mouseout", mouseHideTooltip);

				// Remove old links
				beziers.exit().remove();
			};

			$('#highlightAll').on('click', function(){
				if (!showAll){
					showBeziers();
					showAll = true;
				} else {
					hideSpecificLinks(null, true);
					showAll = false;
				}
			});

			if (showAll){
				showBeziers();
				ignoreHover = true;
			}
			
			var mouseover = function(d){
				if (!showAll && !ignoreHover && (this.style.opacity === "" || (+this.style.opacity > 0))){
					linklayer.selectAll("path").remove();
					// Get link data for this node
					var nodelinks = spatialsankey.links().filter(function(link){
						return showingAll ? link.source : link.source == nodes.features[d].npi || link.target == nodes.features[d].npi;
					});

					// Add data to link layer
					var beziers = linklayer.selectAll("path").data(nodelinks);
					var link =  DOMPurify.sanitize(spatialsankey.link(options));


					// Draw new links
					beziers.enter()
						.append("path")
						.attr("d", link)
						.attr('class', 'bezier')
						.attr('id', function(d){return d.id})
						.on("mousemove", mouseShowTooltip)
						.style("stroke-width",  DOMPurify.sanitize(spatialsankey.link(options?options:null).width()))
						.style("stroke", function(a){
							if (!showingAll){
								return a.source == NPI || a.target == NPI ? LOYAL_LINES : LEAKAGE_LINES;
							}
						})
						.on("mousemove", mouseShowTooltip);

					// Remove old links
					beziers.exit().remove();

					if (showingAll){
						beziers.transition().style("stroke", function(link){
							return link.source == nodes.features[d].npi ? LEAKAGE_LINES : LOYAL_LINES && link.target == nodes.features[d].npi ? LEAKAGE_LINES : LOYAL_LINES;
						});
					}

					// Hide inactive nodes
					var circleUnderMouse = this;
					nodelayer.selectAll('circle').transition().style('opacity',function (d) {
						return (nodes.features[d].type == "target" || nodes.features[d].type == "comp" || this === circleUnderMouse) ? NODE_OPACITY : 0;
					});
				}

				if (nodes.features[d].type == 'target' || nodes.features[d].type == 'comp'){
					hoveredOverHospitalNode = true;
				} else {
					hoveredOverHospitalNode = false;
				}
			};

			var showAllNodes = function(d) {
				var checked = getCheckedInputs("checked");
				if (!ignoreHover || checked.length == 0){
					circs.transition().style('opacity', NODE_OPACITY);
				}
			};

			var mouseHideTooltip = function() {
				d3.select("#geoflow-tooltip").classed("hidden", true);
			};

			var mouseShowTooltip = function(d) {
				if (!ignoreHover) {
					var mapBoundingClientRect = geoflowComponentElem.getBoundingClientRect();
					var xPosition = d3.event.clientX - (mapBoundingClientRect.left + 70);// location minus half width of tooltip
					var yPosition = d3.event.clientY - (mapBoundingClientRect.top - 20);// location plus drop 20px below cursor
	
					if (d.source && d.target){// is tooltip for a link
						headerText = null;
	
						var $body = $('<p></p>');
						
						function createLink(node) {
							$('<a></a>')
								.text(node.name)
								.attr('href', options.generateHomeUrl(node.npi))
								.attr('target', '_blank')
								.appendTo($body);
						}	
						
						createLink(hoveredOverHospitalNode ? nodes.features[d.target] : nodes.features[d.source]);
						
						$('<strong></strong>')
							.text(hoveredOverHospitalNode ? ' receives ': ' refers ')
							.appendTo($body);
	
						$('<span></span>')
							.text(formatNumber(d.flow) + (hoveredOverHospitalNode ? ' visits from ' : ' visits to '))
							.appendTo($body);
	
						createLink(hoveredOverHospitalNode ? nodes.features[d.source] : nodes.features[d.target]);
	
						bodyText = $('<div></div>').append($body).html();
	
					} else {
						var firstname = nodes.features[d].name.replace(".", "").split(" ")[0];
						var lastname = nodes.features[d].name.replace(".", "").split(" ")[1];
						var inputs = getCheckedInputs("all");
						if (inputs.indexOf(nodes.features[d].npi) == -1){
							$('#recentLegend').prepend(DOMPurify.sanitize('<label><input class="recentInputList" type="checkbox" id='+nodes.features[d].npi+' value="marker">'+firstname +" "+ lastname+'</label><br>'));
						}
	
						var $headerText = $('<a target="_blank"></a>')
							.attr('href', options.generateHomeUrl(nodes.features[d].npi))
							.text(nodes.features[d].name);
							
						headerText = $('<span></span>').append($headerText).html();
	
						var inputs = $("#recentLegend > label");
						
						inputs.each(function(i, elem){
							if (i > RECENT_LIST_LENGTH){
								$(inputs[i]).next('br').remove();
								$(inputs[i]).remove();
							}
						});
	
						$('#'+DOMPurify.sanitize(nodes.features[d].npi)).on('click', function(){
							hideSpecificLinks(nodes.features[d].npi);
							if ($(this)[0].checked){
								specificLinks(nodes.features[d].npi);
							}
						});
	
						if (hoveredOverHospitalNode){
							bodyText = '<p><strong>Inflow:</strong> ' + formatNumber(nodes.features[d].aggregate_inflows);
						} else {
							bodyText = '<p><strong>Outflow:</strong> ' + formatNumber(nodes.features[d].aggregate_outflows) + '</p>'
									 + '<p><strong>Total Charges:</strong> $' +  formatNumber(nodes.features[d].charges) + '</p>'
									 + '<p><strong>Share to Competitors:</strong> ' + Math.round(nodes.features[d].leakage*10000)/100 + '%';
						}
					}

					d3.select("#geoflow-tooltip")
						.style("left", xPosition + "px")
						.style("top", yPosition + "px");
	
					d3.select("#geoflow-tooltip #heading")
						.html(function(){
							return headerText;
					});
	
					d3.select("#geoflow-tooltip #visits")
						.html(function(){
							var communityAbbrs = options.pic(d);
							if (communityAbbrs.length){
								communityAbbrs = communityAbbrs.map(function(d){
									return d.abbr;
								}).join(', ');
							}
							return (communityAbbrs.length ? bodyText + '<br/><b>Communities: </b>' + communityAbbrs : bodyText);
					});
	
					d3.select("#geoflow-tooltip").classed("hidden", false);
				}	
			};

			var hideSpecificLinks = function(npi, all){

				var checked = getCheckedInputs("checked");
				linklayer.selectAll("path").remove();
				// Get link data for this node
				var nodelinks = spatialsankey.links().filter(function(link){
					return checked.indexOf(link.target) > -1 || checked.indexOf(link.source) > -1;
				});

				// Add data to link layer
				var beziers = linklayer.selectAll("path").data(nodelinks);
				var link =  DOMPurify.sanitize(spatialsankey.link(options));

				// Draw new links
				beziers.enter()
					.append("path")
					.attr("d", link)
					.attr('class', 'bezier')
					.attr('id', function(d){return npi})
					.style("stroke-width",  DOMPurify.sanitize(spatialsankey.link(options?options:null).width()))
					.style("stroke", function(a){
						if (!showingAll){
							return a.source == NPI || a.target == NPI ? LOYAL_LINES : LEAKAGE_LINES;
						}
					})
					.on("mousemove", mouseShowTooltip);

				// Remove old links
				beziers.exit().remove();

				if (showingAll){
					beziers.transition().style("stroke", function(link){
					if (!all){
						return link.source == npi ? LEAKAGE_LINES : LOYAL_LINES && link.target == npi ? LEAKAGE_LINES : LOYAL_LINES;
					}
				  });
				}

				if (linkType == 'target' || linkType == 'comp'){
					hoveredOverHospitalNode = true;
				} else {
					hoveredOverHospitalNode = false;
				}

				// Hide inactive nodes
				var circleUnderMouse = this;
				circs.transition().style('opacity',function (d) {
					var checked = getCheckedInputs('checked');
					return (nodes.features[d].type == "target" || nodes.features[d].type == "comp" || this === circleUnderMouse || checked.indexOf(nodes.features[d].npi) > -1) ? NODE_OPACITY : 0;
				});

				// show filtered nodes when input has none checked
				if (checked.length == 0){
					circs.call(showFilteredNodes);
				}
			};

			var specificLinks = function(npi){
				// Hide inactive nodes
				var circleUnderMouse = this;
				circs.transition().style('opacity',function (d) {
					var checked = getCheckedInputs('checked');
					return (nodes.features[d].type == "target" || nodes.features[d].type == "comp" || this === circleUnderMouse || checked.indexOf(nodes.features[d].npi) > -1) ? NODE_OPACITY : 0;
				});
			};

			var showSpecificLinks = function(npi) {
				var circleUnderMouse = this;
				circs.transition().style('opacity',function (d) {
					return NODE_OPACITY;
				});
			};

			var showFilteredNodes = function() {
				var treeFilteredNpiArr = [];
				$('.leaflet-layer-tree-control-layers-open, .leaflet-layer-tree-control-layers-closed').find("[id*='_specializations_'][id*='npi_']:not(.leaflet-layer-tree-control-select-layers-none)").each(function(){
					var npi = /npi_([0-9]{10})/.exec(this.id)[1];
					 treeFilteredNpiArr.push(npi);
				});
				
				var circleUnderMouse = this;
				
				nodelayer.selectAll('circle')
					.transition()
					.style('opacity', function(d){
						return (treeFilteredNpiArr.indexOf(d) > -1 ? NODE_OPACITY : 0);
					});
			};

			// hide tooltip when clicking outside circ
			$('body').on('click', function(e){
				var container = $('#geoflow-tooltip');
				if (!container.is(e.target) && e.target.nodeName == 'svg'){
					mouseHideTooltip();
				}
			});


			// hide tooltip on zoom start
			map.on('zoomstart', mouseHideTooltip);

			// hide tooltip on move start
			map.on('movestart', mouseHideTooltip);

			// Draw nodes
			var node = spatialsankey.node({
				minradius: 8,
				maxradius: 64
			});
			
			var circs = nodelayer.selectAll("circle")
				.data(Object.keys(nodes.features));
				
			circs
				.enter()
				.append("circle")
				.attr("cx", node.cx)
				.attr("cy", node.cy)
				.attr("r", node.r)
				.style("fill", function(d){
					if (d != NPI && options.pic(d).length) {
						return 'fff';
					} else {
						return node.fill(d, NPI);
					}
				})
				.style("stroke", STROKE)
				.style("stroke-width", STROKE_WIDTH)
				.attr('stroke',function(d){
					if (options.pic(d).length) {
						return '#2b3038';
					}
				})
				.attr('stroke-width', function(d){
					if (options.pic(d).length) {
						return '2';
					} else {
						return '0';
					}
				})
				.attr("opacity", NODE_OPACITY)
				.attr("data-taxonomytype", function(d){
					return nodes.features[d].taxonomytype;
				})
				.attr("data-classification", function(d){
					return nodes.features[d].classification;
				})
				.attr("data-specialization", function(d){
					return nodes.features[d].specialization;
				})
				.on('mouseover', mouseover)
				.on("mouseup", mouseShowTooltip)
				.on("mouseout", function() {
					if (getCheckedInputs('checked') == 0) {
						showFilteredNodes();
					}
				});
			
			
			
			// Adopt size of drawn objects after leaflet zoom reset
			var zoomend = function(e){
				linklayer.selectAll("path")
					.attr("d",  DOMPurify.sanitize(spatialsankey.link(options?options:null)));
				
				nodelayer.selectAll('circle')
					.attr("cx", node.cx)
				 	.attr("cy", node.cy);
				 	
			};

			map.on("zoomend", zoomend);

			$('#loading').hide();

		}; // end .get data 
		
		handleData(geojsonData);
		
 	};
	return this;
})(jQuery);